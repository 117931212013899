import { RouteRecordRaw } from 'vue-router';
interface MetaProp {
  meta: {
    name: string;
    activePath: string;
    activeIndex: number;
  };
}

type ErpRouter = RouteRecordRaw & MetaProp;
const erpRouter: ErpRouter[] = [
  {
    meta: {
      name: '任务',
      activePath: '/erp/taskList',
      activeIndex: 0,
    },
    path: '/erp/taskList',
    name: 'erptaskList',
    component: () => import('../views/erp/task/taskList.vue'),
  },
  {
    meta: {
      name: '期货跟进',
      activePath: '/erp/order/orderfollow',
      activeIndex: 1,
    },
    path: '/erp/order/orderfollow', // 订单列表
    name: 'OrderList',
    component: () => import('../views/erp/order/orderFollow/list.vue'),
  },
  {
    meta: {
      name: '订单详情',
      activePath: '/erp/order/orderfollow',
      activeIndex: 1,
    },
    path: '/erp/order/orderDetail',
    name: 'OrderDetail',
    component: () => import('../views/erp/order/orderFollow/orderDetail.vue'),
  },
  {
    meta: {
      name: '批文管理',
      activePath: '/erp/order/license',
      activeIndex: 1,
    },
    path: '/erp/order/license',
    name: 'LicenseList',
    component: () => import('../views/erp/order/license/list.vue'),
  },
  {
    meta: {
      name: '查看批文订单',
      activePath: '/erp/order/license',
      activeIndex: 1,
    },
    path: '/erp/order/licenseOrderList',
    name: 'LicenseOrderList',
    component: () => import('../views/erp/order/license/listOrder.vue'),
  },
  {
    meta: {
      name: '自动证管理',
      activePath: '/erp/order/autocert',
      activeIndex: 1,
    },
    path: '/erp/order/autocert',
    name: 'AutoCertList',
    component: () => import('../views/erp/order/autocert/list.vue'),
  },
  {
    meta: {
      name: '上游业务合同',
      activeIndex: 1,
      activePath: '/erp/order/contract',
    },
    path: '/erp/order/contract',
    name: 'ContractOrderList',
    component: () => import('../views/erp/order/contract/list.vue'),
  },
  {
    meta: {
      name: '上传记录',
      activePath: '/erp/order/contract',
      activeIndex: 1,
    },
    path: '/erp/order/uploadLog',
    name: 'UploadLog',
    component: () => import('../views/erp/order/contract/listLog.vue'),
  },
  {
    meta: {
      name: '现货跟进',
      activePath: '/erp/order/orderspot',
      activeIndex: 1,
    },
    path: '/erp/order/orderspot',
    name: 'Orderspot',
    component: () => import('../views/erp/order/orderspot/list.vue'),
  },
  {
    meta: {
      name: '订单详情',
      activePath: '/erp/order/orderspot',
      activeIndex: 1,
    },
    path: '/erp/order/orderSpotDetail',
    name: 'OrderSpotDetail',
    component: () => import('../views/erp/order/orderspot/orderDetail.vue'),
  },
  {
    meta: {
      name: '申请预付提醒',
      activeIndex: 2,
      activePath: '/erp/payplan/advancepay',
    },
    path: '/erp/payplan/advancepay',
    name: 'advancepayList',
    component: () => import('../views/erp/payplan/advancepay/list.vue'),
  },
  {
    meta: {
      name: '申请尾款提醒',
      activeIndex: 2,
      activePath: '/erp/payplan/balancepay',
    },
    path: '/erp/payplan/balancepay',
    name: 'balancepayList',
    component: () => import('../views/erp/payplan/balancepay/list.vue'),
  },
  {
    meta: {
      name: '付款申请',
      activeIndex: 2,
      activePath: '/erp/payplan/payapply',
    },
    path: '/erp/payplan/payapply',
    name: 'payapplyList',
    component: () => import('../views/erp/payplan/payapply/list.vue'),
  },
  {
    meta: {
      name: '付款记录',
      activeIndex: 2,
      activePath: '/erp/payplan/payrecord',
    },
    path: '/erp/payplan/payrecord',
    name: 'payrecordList',
    component: () => import('../views/erp/payplan/payrecord/list.vue'),
  },
  {
    meta: {
      name: '应收项目',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/accrued',
    name: 'accrued',
    component: () => import('../views/erp/financial/accrued/list.vue'),
  },
  {
    meta: {
      name: '查看应收项目',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/accruedDetail',
    name: 'accruedDetail',
    component: () => import('../views/erp/financial/accrued/detail.vue'),
  },
  // 现货详情
  {
    meta: {
      name: '查看应收项目',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/xhAccruedDetail',
    name: 'xhAccruedDetail',
    component: () => import('../views/erp/financial/accrued/xhDetail.vue'),
  },
  {
    meta: {
      name: '应收审核记录',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/arAuditList',
    name: 'arAuditList',
    component: () => import('../views/erp/financial/accrued/arAuditList.vue'),
  },
  {
    meta: {
      name: '修改应收审核记录',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/modifyList',
    name: 'modifyList',
    component: () => import('../views/erp/financial/accrued/modifyList.vue'),
  },
  {
    meta: {
      name: '应收删除记录',
      activePath: '/erp/financial/accrued',
      activeIndex: 3,
    },
    path: '/erp/financial/delArAuditList',
    name: 'delArAuditList',
    component: () => import('../views/erp/financial/accrued/delArAuditList.vue'),
  },
  {
    meta: {
      name: '应付项目',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/accruedCope',
    name: 'accruedCope',
    component: () => import('../views/erp/financial/accrued/listCope.vue'),
  },
  {
    meta: {
      name: '应付审核记录',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/apAuditList',
    name: 'apAuditList',
    component: () => import('../views/erp/financial/accrued/apAuditList.vue'),
  },
  {
    meta: {
      name: '修改应付审核记录',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/modifyApList',
    name: 'modifyApList',
    component: () => import('../views/erp/financial/accrued/modifyApList.vue'),
  },
  {
    meta: {
      name: '应付删除记录',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/delApAuditList',
    name: 'delApAuditList',
    component: () => import('../views/erp/financial/accrued/delApAuditList.vue'),
  },
  {
    meta: {
      name: '查看应付项目',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/accruedDetailCope',
    name: 'detailCope',
    component: () => import('../views/erp/financial/accrued/detailCope.vue'),
  },
  // 现货的应收应付
  {
    meta: {
      name: '查看应付项目',
      activePath: '/erp/financial/accruedCope',
      activeIndex: 3,
    },
    path: '/erp/financial/xhAccruedDetailCope',
    name: 'xhDetailCope',
    component: () => import('../views/erp/financial/accrued/xhDetailCope.vue'),
  },
  // 收款
  {
    meta: {
      name: '收款',
      activePath: '/erp/financial/collection',
      activeIndex: 3,
    },
    path: '/erp/financial/collection',
    name: 'collection',
    component: () => import('../views/erp/financial/collection/list.vue'),
  },
  {
    meta: {
      name: '审核记录',
      activePath: '/erp/financial/collection',
      activeIndex: 3,
    },
    path: '/erp/financial/collectionAuditList',
    name: 'collectionAuditList',
    component: () => import('../views/erp/financial/collection/auditList.vue'),
  },
  {
    meta: {
      name: '查看收款',
      activePath: '/erp/financial/collection',
      activeIndex: 3,
    },
    path: '/erp/financial/collectionDetail',
    name: 'collectionDetail',
    component: () => import('../views/erp/financial/collection/detail.vue'),
  },
  {
    meta: {
      name: '审核收款订单',
      activePath: '/erp/financial/collection',
      activeIndex: 3,
    },
    path: '/erp/financial/auditDetail',
    name: 'collectionAuditDetail',
    component: () => import('../views/erp/financial/collection/auditDetail.vue'),
  },
  // 付款
  {
    meta: {
      name: '付款',
      activePath: '/erp/financial/payment',
      activeIndex: 3,
    },
    path: '/erp/financial/payment',
    name: 'payment',
    component: () => import('../views/erp/financial/payment/list.vue'),
  },
  {
    meta: {
      name: '查看付款',
      activePath: '/erp/financial/payment',
      activeIndex: 3,
    },
    path: '/erp/financial/detail',
    name: 'paymentDetail',
    component: () => import('../views/erp/financial/payment/detail.vue'),
  },
  {
    meta: {
      name: '审核付款',
      activePath: '/erp/financial/payment',
      activeIndex: 3,
    },
    path: '/erp/financial/audit',
    name: 'paymentAudit',
    component: () => import('../views/erp/financial/payment/audit.vue'),
  },
  {
    meta: {
      name: '收款账号',
      activePath: '/erp/financial/payment',
      activeIndex: 3,
    },
    path: '/erp/financial/paymentAccount',
    name: 'paymentAccount',
    component: () => import('../views/erp/financial/paymentAccount/list.vue'),
  },
  {
    meta: {
      name: '结算单生成',
      activePath: '/erp/financial/statements',
      activeIndex: 3,
    },
    path: '/erp/financial/statements',
    name: 'statements',
    component: () => import('../views/erp/financial/statements/list.vue'),
  },
  {
    meta: {
      name: '结算单审核',
      activePath: '/erp/financial/signlist',
      activeIndex: 3,
    },
    path: '/erp/financial/signlist',
    name: 'signList',
    component: () => import('../views/erp/financial/statements/signList.vue'),
  },
  {
    meta: {
      name: '审核记录',
      activePath: '/erp/financial/signlist',
      activeIndex: 3,
    },
    path: '/erp/financial/auditStatement',
    name: 'auditStatement',
    component: () => import('../views/erp/financial/statements/auditStatement.vue'),
  },
  //
  {
    meta: {
      name: '来票',
      activePath: '/erp/financial/vote',
      activeIndex: 3,
    },
    path: '/erp/financial/vote',
    name: 'vote',
    component: () => import('../views/erp/financial/vote/list.vue'),
  },
  // 开票
  {
    meta: {
      name: '开票',
      activePath: '/erp/financial/invoice',
      activeIndex: 3,
    },
    path: '/erp/financial/invoice',
    name: 'Invoice',
    component: () => import('../views/erp/financial/invoice/list.vue'),
  },
  {
    meta: {
      name: '查看发票',
      activePath: '/erp/financial/invoice',
      activeIndex: 3,
    },
    path: '/erp/financial/invoiceDetail',
    name: 'invoiceDetail',
    component: () => import('../views/erp/financial/invoice/detail.vue'),
  },
  {
    meta: {
      name: '开票审核记录',
      activePath: '/erp/financial/invoice',
      activeIndex: 3,
    },
    path: '/erp/financial/invoiceAuditList',
    name: 'invoiceAuditList',
    component: () => import('../views/erp/financial/invoice/auditList.vue'),
  },
  {
    meta: {
      name: '开票申请记录',
      activePath: '/erp/financial/invoice',
      activeIndex: 3,
    },
    path: '/erp/financial/invoiceLog',
    name: 'invoiceLog',
    component: () => import('../views/erp/financial/invoice/invoiceLog.vue'),
  },
  {
    meta: {
      name: '批量申请开票',
      activePath: '/erp/financial/invoice',
      activeIndex: 3,
    },
    path: '/erp/financial/batchPage',
    name: 'batchPage',
    component: () => import('../views/erp/financial/invoice/batchPage.vue'),
  },
  // 货物
  {
    meta: {
      name: '送货',
      activePath: '/erp/inventory/delivery',
      activeIndex: 4,
    },
    path: '/erp/inventory/delivery',
    name: 'deliveryList',
    component: () => import('../views/erp/inventory/delivery/list.vue'),
  },
  {
    meta: {
      name: '库存',
      activePath: '/erp/inventory/inventory',
      activeIndex: 4,
    },
    path: '/erp/inventory/inventory',
    name: 'inventoryList',
    component: () => import('../views/erp/inventory/inventory/list.vue'),
  },
  {
    meta: {
      name: '出库记录',
      activePath: '/erp/inventory/deliveryRecord',
      activeIndex: 4,
    },
    path: '/erp/inventory/deliveryRecord',
    name: 'deliveryRecordList',
    component: () => import('../views/erp/inventory/deliveryRecord/list.vue'),
  },
  // 财务
  {
    meta: {
      name: '订单利润表',
      activePath: '/erp/reportDerived/profitOrder',
      activeIndex: 5,
    },
    path: '/erp/reportDerived/profitOrder',
    name: 'profitOrder',
    component: () => import('../views/erp/reportDerived/profitOrder/list.vue'),
  },
  {
    meta: {
      name: '客户台账',
      activePath: '/erp/reportDerived/customerParameter',
      activeIndex: 5,
    },
    path: '/erp/reportDerived/customerParameter',
    name: 'customerParameter',
    component: () => import('../views/erp/reportDerived/customerParameter/list.vue'),
  },
  {
    meta: {
      name: '单证日报',
      activePath: '/erp/businessReport/doc',
      activeIndex: 6,
    },
    path: '/erp/businessReport/doc',
    name: 'businessReportDoc',
    component: () => import('../views/erp/businessReport/doc/list.vue'),
  },
  {
    meta: {
      name: '资管日报',
      activePath: '/erp/businessReport/info',
      activeIndex: 6,
    },
    path: '/erp/businessReport/info',
    name: 'businessReportInfo',
    component: () => import('../views/erp/businessReport/info/list.vue'),
  },
  {
    meta: {
      name: '电子合同',
      activePath: '/erp/electronContract',
      activeIndex: 7,
    },
    path: '/erp/electronContract',
    name: 'electronContract',
    component: () => import('../views/erp/setting/electronContract/list.vue'),
  },
  {
    meta: {
      name: '牛肉指数',
      activePath: '/erp/beefindex',
      activeIndex: 7,
    },
    path: '/erp/beefindex',
    name: 'beefIndex',
    component: () => import('../views/erp/setting/beefIndex/list.vue'),
  },
  {
    meta: {
      name: '意见反馈',
      activePath: '/erp/feedback',
      activeIndex: 7,
    },
    path: '/erp/feedback',
    name: 'erpFeedback',
    component: () => import('../views/erp/setting/feedBack/list.vue'),
  },
  {
    meta: {
      name: '导出报表',
      activePath: '/erp/exportTable/index',
      activeIndex: 8,
    },
    path: '/erp/exportTable/index',
    name: 'exportTable',
    component: () => import('../views/erp/exportTable/index.vue'),
  },
];

export default erpRouter;
