import { ElLoading } from 'element-plus';

export type LoadingType = {
  showLoading: (target: string, text?: string) => void;
  closeLoading: () => void;
};
export default function useLoading(): LoadingType {
  let loadingInstance: any | null = null;
  const showLoading = (target: string, text = '正在加载...') => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    const options: any = {
      target: target,
      text: text,
      customClass: 'loading-spinner',
    };
    loadingInstance = ElLoading.service(options);
  };
  const closeLoading = () => {
    if (loadingInstance) {
      loadingInstance.close();
      loadingInstance = null;
    }
  };
  return {
    showLoading,
    closeLoading,
  };
}
