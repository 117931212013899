
import { httpGet, httpPost } from '@/api';
import { ajaxLoading, errorMessage, successMessage, setTitleLabel } from '@/utils/publicMethods';
import { computed, defineComponent, PropType } from 'vue';
import useFormData from './useFormData';
import useCountryPlant from '@/hooks/useCountryPlant';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import DictSelect from '@/components/DictSelect/DictSelect.vue';

type PropData = {
  category: number | string;
  country: string;
  supplierCompanyType: string;
  customerName: string;
};
export default defineComponent({
  components: { ChooseMan, DictSelect },
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
      default: 1,
    },
    /** 客户id  */
    eventId: {
      type: String as PropType<string>,
    },
    /** 事件状态 1.客户 2.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    data: {
      type: Object as PropType<PropData>,
    },
    /** 客户或商机信息 */
    eventData: {
      type: Object as PropType<PropData>,
    },
  },
  emits: ['close', 'success'],
  setup(props, ctx) {
    const { setContactList } = useCountryPlant();
    const { rules, formData, formRef, setFormData, customerOptions, contactList } = useFormData();
    const statusForBussiness = computed(() => {
      return [
        { label: '跟进商机', value: 1 },
        { label: '下单失败', value: 3 },
      ];
    });
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitForm = async () => {
      ajaxLoading.lock();
      const { contactId, customerId, id, name, principalId, relevantId, remark, source, status } = formData;
      const params = {
        operateType: props.formType,
        version: '',
        contactId,
        customerId,
        id,
        name,
        principalId,
        relevantId,
        remark,
        source,
        status,
      };
      const res = await httpPost('/malicrm/business/operateBusiness', params);
      ajaxLoading.unLock();
      if (res.code === 200) {
        successMessage('已保存', 2000);
        ctx.emit('success', true);
        close(true);
      } else {
        errorMessage(res.message);
      }
    };

    const searchCustomer = async (name) => {
      const params = {
        customerName: name,
      };
      const res = await httpPost('/malicrm/business/selectCustomer', params);
      if (res.code === 200) {
        customerOptions.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const changeCustomer = () => {
      if (customerOptions.value.length <= 0) {
        searchCustomer('');
      }
    };
    /** 提交表单 校验重复 */
    const saveFormData = async () => {
      if (formRef.value) {
        formRef.value.validate(async (valid: any) => {
          if (valid) {
            submitForm();
          }
        });
      }
    };
    /** 获取客户相关人员 */
    const getCustomerRelevant = async (customerId: string) => {
      const res = await httpGet(`/malicrm/customer/getCustomerUserByCustomerId/${customerId}`);
      if (res.code === 200) {
        const ids = res.result.map((v) => v.userId);
        const name = res.result.map((v) => v.userName).toString('、');
        formData.principalId = ids;
        formData.principal = name;
      }
    };
    const getContactList = async (id, isUpdate) => {
      getCustomerRelevant(id);
      const params = {
        customerId: id,
        orderBy: null,
        pageNum: 1,
        pageSize: 9999999,
      };

      const res = await httpPost('/malicrm/customer/queryContact', params);
      if (res.code === 200) {
        if (!isUpdate) {
          formData.contactId = [];
        }
        contactList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };

    const getEditContactList = async (id) => {
      const params = {
        customerId: id,
        orderBy: null,
        pageNum: 1,
        pageSize: 9999999,
      };
      const res = await httpPost('/malicrm/customer/queryContact', params);
      if (res.code === 200) {
        contactList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    // 编辑数据
    const editForm = async (params) => {
      // 这里全部都是业务数据转化处理
      const res = await httpGet(`/malicrm/business/businessDetail/${params.id}`);
      if (res.code === 200) {
        const data = res.result;
        setFormData(data);
        formData.operateType = 2;
        formData.remark = data.businessRemark;
        formData.contactId = data.contactsId;
        setContactList(data.customerId);
      } else {
        errorMessage(res.message);
      }
    };
    /** 字符处理 */
    const getName = (prop, value) => {
      value = value.replace(/[^-——a-zA-Z0-9\u4E00-\u9FA5\s]/g, ''); // 不允许输入非法字符
      formData[prop] = value;
    };
    searchCustomer('');
    if ((props.formType as number) > 1) {
      setFormData(props.data);
      formData.relevantId = (props.data as any).relevantId;
      formData.remark = (props.data as any).businessRemark;
      formData.contactId = (props.data as any).contactsId;
      getEditContactList((props.data as any).customerId);
    }
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      getName,
      setTitleLabel,
      contactList,
      statusForBussiness,
      searchCustomer,
      customerOptions,
      getContactList,
      changeCustomer,
      getCustomerRelevant,
      editForm,
    };
  },
});
