import { ElMessage, ElMessageBox } from 'element-plus';
import { httpPost, ajax, httpGet } from '@/api';
import { ConsType, ISelectItem } from '@/types/type';
import router from '@/router';
import dayjs from 'dayjs';
import { store } from '@/store';
import axios from 'axios';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { ref } from 'vue';
import XEUtils from 'xe-utils';
/** 空字符过滤器 */
export function filterName(value: any) {
  if (value === null || value === '') {
    return '-';
  } else {
    return value;
  }
}

/** 格式化金额 处理小数格式化 四舍五入 */
export function _formatFloat(src: number, pos = 2) {
  return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
}
/** 千分位分隔符 */
export function _thousandBitSeparator(num: string | number) {
  if (typeof num === 'number') {
    num = String(num);
  }
  let str = '';
  if (num) {
    if (num && num.indexOf('.') !== -1) {
      str = num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ',';
      });
    } else {
      str = num.replace(/(\d{1,3})(?=(\d{3})+$)/g, function ($0, $1) {
        return $1 + ',';
      });
    }
  } else {
    str = '';
  }

  return str;
}
/** 错误提示框 */
export function errorMessage(msg: string, duration?: number) {
  ElMessage({
    showClose: true,
    message: msg,
    type: 'error',
    duration: duration || 2000,
  });
}
/** 错误提示框 */
export function successMessage(msg: string, duration?: number) {
  ElMessage({
    showClose: true,
    message: msg,
    type: 'success',
    duration: duration || 2000,
  });
}
/** 兼容旧方法 */
export function successTip(msg: string, duration?: number) {
  successMessage(msg, duration);
}
/** 兼容旧方法 */
export function errorTip(msg: string, duration?: number) {
  errorMessage(msg, duration);
}

/** 设置页面跳转参数信息  */
export function setRouterParams(name: string, params?: any) {
  if (params) {
    localStorage.setItem(name + 'Params', JSON.stringify(params));
  }
}
/** 获取页面跳转参数信息
 * @param name：string
 */
export function getRouterParams(name: string) {
  const params = localStorage.getItem(name + 'Params');
  return JSON.parse(params as string);
}
/** router 公共跳转方法通过name */
export function loadPage(name: string, params?: any) {
  setRouterParams(name, params);
  if (name) {
    router.push({
      name,
      params,
    });
  }
}

export function isJsonObj(str: string) {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}
/** 检测空对象方法 */
export function hasEmptyObject(obj: any) {
  const arr = Object.keys(obj);
  return arr.length <= 0;
}

export function setDictValue(valueStr: string, key: string): ISelectItem[] {
  const isJson = isJsonObj(valueStr);

  if (isJson) {
    const obj = JSON.parse(valueStr);
    const arr: ISelectItem[] = [];
    Object.keys(obj).forEach((v) => {
      const selectItem = {
        label: obj[v],
        value: !isNaN(Number(v)) ? Number(v) : v, // 判断是否是数字 ，是就转换成数字
      };
      arr.push(selectItem);
    });
    return arr;
  } else {
    return [
      {
        label: key,
        value: valueStr,
      },
    ];
  }
}
/** 获取字典数据类型 */
export async function setDictOptions() {
  // 这里手动处理兼容部分类型不对的情况
  const customKey = ['ap_fee_type', 'ar_fee_type', 'sundry_fee_type_payment', 'invoice_output_type'];
  const dictObjs: ConsType = {};
  // let key = '';
  const res = await httpPost('/malicrm/simple/queryConsPageList', {
    pageNum: 1,
    pageSize: 9999,
  });
  if (res.code === 200) {
    const data = res.result.list;
    data.forEach((item) => {
      dictObjs[item.idKey] = setDictValue(item.value, item.idKey);
      // 生成描述文件 type.d.ts ConsKey 值使用
      // key += `|"${item.idKey}"`;
    });
  }
  const res2 = await httpGet(`/malicrm/simple/getConsDictIncacheByKeyArrayNew?keyList=${customKey.toString()}`);
  if (res2.code === 200) {
    res2.result.forEach((v) => {
      dictObjs[v.dictKey] = v.entryVoList;
    });
  } else {
    // 报错就返回空
  }

  return dictObjs;
}

export function refreshTable(refs, isKeep) {
  refs.value.refreshTable(isKeep);
}

let isAjaxLoading = false;
/** 重复提交内容处理 */
export const ajaxLoading = {
  lock: () => {
    if (isAjaxLoading === false) {
      isAjaxLoading = true;
    } else {
      throw new Error('重复提交');
    }
  },
  unLock: () => {
    isAjaxLoading = false;
  },
};

/** 设置crm侧边栏名称数字展示 例如沟通记录（99） */
export function setTitleNum(name: string, total: number) {
  let str = '';
  if (total > 0) {
    if (total > 99) {
      str = 99 + '+';
    } else {
      str = total.toString();
    }
    return `${name}(${str})`;
  } else {
    return name;
  }
}
export function erpConfirm(msg) {
  return new Promise<void>((resolve, reject) => {
    ElMessageBox({
      title: '提示',
      message: msg,
      showCancelButton: true,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
        return false;
      });
  });
}
/** 设置表单动态标题 */
export function setTitleLabel(type: number, options?: string[]) {
  const strArr = options || ['新增', '编辑', '查看'];
  return strArr[type - 1];
}

type TimeFormat = 'YYYY-MM-DD' | 'YYYY-MM' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm:ss' | 'YYYYMMDDHHmmss';
/** 格式化时间展示 */
export function formatTime(date: any, format: TimeFormat) {
  if (date === '' || date === null) {
    return null;
  } else {
    return dayjs(date).format(format);
  }
}

/** 导出文件传参 */
type ExportFile = {
  type: 'POST' | 'GET';
  url: string;
  data?: any;
  fileNameStart?: string;
};

/** 导出文件 */
export async function exportFile(param: ExportFile) {
  const { type, url, data, fileNameStart } = param;
  const res = await ajax({
    url,
    method: type,
    data,
    responseType: 'blob',
  });
  if (res && res.status === 200) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([res.data]));
    const exposeHeaders = res.headers['content-disposition']; // 从response头部获取文件名信息
    let fileName;
    // 有的版本chrome或其他的浏览器拿到不response headers。判断是否获取到了文件名信息
    if (exposeHeaders) {
      // exposeHeaders = JSON.parse(res.headers['access-control-expose-headers']);
      fileName = exposeHeaders.split(';')[1].split('=')[1].replace(/^"|"$/g, ''); // 获取文件名名
      fileName = decodeURI(fileName); // urldecode 解码
    } else {
      fileName = !fileNameStart ? formatTime(new Date(), 'YYYYMMDDHHmmss') + '.xlsx' : fileNameStart + formatTime(new Date(), 'YYYYMMDDHHmmss') + '.xlsx';
    }
    link.download = fileName; // fileName;
    link.click();
    return true;
  } else {
    return false;
  }
}

const checkPermission = function (bindPermissions) {
  const permissions = store.getters.getPermissions;
  let result = 0;
  for (const permission of permissions) {
    for (const bind of bindPermissions) {
      if (permission === bind) {
        result += 1;
      }
    }
  }
  return result > 0;
};
export function hasPermission(bindPermissions) {
  const permissions: string[] = [];

  if (bindPermissions === 'isAdmin') {
    // 超级管理员可见
    if (Number(store.state.user?.userId) === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    if (typeof bindPermissions === 'string') {
      permissions.push(bindPermissions);
    } else {
      if (typeof bindPermissions === 'boolean') {
        return bindPermissions;
      } else {
        permissions.concat(bindPermissions);
      }
    }
    return checkPermission(permissions);
  }
}
/** 查看下载文件 */
export function showFiles(name: string, url: string, status = true, emptyNoTip = false) {
  if (url === '' || url === null) {
    if (!emptyNoTip) {
      console.log('todo');
    } else {
      errorMessage('链接地址不存在');
    }
    return false;
  }
  if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|pdf|PDF|bmp|xlsx|xls|ppt|pptx|doc|docx|mp4)$/.test(url) && status) {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('title', name);
    if (/\.(xlsx|xls|ppt|pptx|doc|docx)$/.test(url)) {
      let fileUrl = encodeURIComponent(url);
      link.href = `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`;
    } else {
      link.href = url;
    }
    link.click();
  } else {
    successMessage('正在下载，请等待');
    let urlPath = '';
    // 如果是minio上传的文件,则加上attname属性才会是下载
    if (url.indexOf('minio') > -1 || url.indexOf('oss') > -1) {
      urlPath = `${url}?attname=${encodeURI(name)}`;
    } else {
      urlPath = `${url}?filename=${name}`;
    }
    console.log(urlPath);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_self');
    link.setAttribute('title', name);
    link.href = urlPath;
    link.click();
  }
}

/** 获取文件名后缀 */
export function getSuffix(name: string) {
  const fileName = name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
  const fileNameLen = name.length; // 取到文件名长度
  const suffix = name.substring(fileName + 1, fileNameLen).toLowerCase(); // 获取到截取的文件名，不区分大小写
  return suffix;
}
/** 获取截取不带后缀的文件名 */
export function getCutFileName(name: string, tag = '.') {
  const fileName = name.lastIndexOf(tag); // 取到文件名开始到最后一个点的长度
  const cutName = name.substring(0, fileName);
  return cutName;
}
/** 转换value-label 格式数据 */
export function transISelectItem(data: string[] | number[]) {
  const selectItem: ISelectItem[] = [];
  data.forEach((v) => {
    selectItem.push({
      label: v,
      value: v,
    });
  });
  return selectItem;
}

export function getFile(url: string) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
}

interface SelectImgList {
  name: string;
  url: string;
}
export let batchCount = ref(0);
// 批量下载
export async function handleBatchDownload(selectImgList: SelectImgList[], zipName: string) {
  const imgList = selectImgList;
  const zip = new JSZip();
  const promises: Promise<any>[] = [];
  imgList.forEach((item) => {
    const promise = getFile(item.url).then((data: any) => {
      zip.file(item.name, data, { binary: true }); // 逐个添加文件
      batchCount.value += 1;
    });
    promises.push(promise);
  });
  let message = ElMessage({
    message: '正在下载，请稍后',
    type: 'warning',
    duration: 0,
  });
  await Promise.all(promises);
  let content = await zip.generateAsync({
    type: 'blob',
  });
  batchCount.value = 0;
  message.close();
  FileSaver.saveAs(content, zipName + '.zip'); // 利用file-saver保存文件
  return true;
}
//检验是否有菜单权限
export function hasMenu(url: string) {
  let menus = store.state.user?.menuList as any;
  if (menus) {
    let tagMenu = XEUtils.filterTree(menus, (item) => (item as any).url === url, { children: 'subMenu' });
    return tagMenu && tagMenu.length > 0;
  } else {
    return false;
  }
}

// 处理表格合并行
export function handleTableData(tableData: [], attr: string, index: number, rowIndex, columnIndex) {
  // tableData: 当前数组对象数据
  // attr, 要合并当前行的信息
  // index, 第几列信息需要合并
  let spanOneArr = [] as any;
  let concatOne = 0;
  tableData.forEach((item, index) => {
    if (index === 0) {
      spanOneArr.push(1);
    } else {
      if (item[attr] === tableData[index - 1][attr]) {
        (spanOneArr[concatOne] as any) += 1;
        spanOneArr.push(0);
      } else {
        spanOneArr.push(1);
        concatOne = index;
      }
    }
  });
  const _row = spanOneArr[rowIndex];
  const _col = _row > 0 ? 1 : 0;
  if (columnIndex === index) {
    return {
      rowspan: _row,
      colspan: _col,
    };
  }
}
