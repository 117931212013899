import { ajax as Ajax } from '@/api';
import {
  erpConfirm,
  errorMessage,
  filterName,
  hasPermission,
  showFiles,
  successMessage,
  _formatFloat,
  _thousandBitSeparator,
} from '@/utils/publicMethods';
const mixin = {
  methods: {
    _formatFloat(src, pos) {
      // 处理小数格式化 四舍五入
      return _formatFloat(src, pos);
    },
    // 校验权限
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    filterName(val) {
      return filterName(val);
    },
    _thousandBitSeparator(str) {
      return _thousandBitSeparator(str);
    },
    $showFiles(name, url, status = true) {
      return showFiles(name, url, status);
    },
    errorTip(msg) {
      errorMessage(msg);
    },
    successTip(msg) {
      successMessage(msg);
    },
    async $erpConfirm(msg) {
      const res = await erpConfirm(msg);
      return res;
    },
    async ajax(param) {
      const { type, url, data, success, responseType, headers } = param;
      const res = await Ajax({
        method: type || 'POST',
        url: url || '',
        data: data || '',
        headers: headers || {},
        responseType: responseType,
      });
      if (success) {
        success(res);
      }
      return res;
    },
  },
};

export default mixin;
