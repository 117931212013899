<template>
  <CrmFormLayout :title="comTitle">
    <div>
      <!-- 查看参数 -->
      <div class="view__form" v-show="!isEdit">
        <div class="view__group">
          <label class="view__label" style="width: auto"
            >客户指定汇率(美元)</label
          >
          <div class="view__input" style="width: 310px">
            {{ filterName(formData.rate1) }}
          </div>
        </div>
        <div class="view__group">
          <label class="view__label" style="width: auto"
            >客户指定汇率(澳元)</label
          >
          <div class="view__input" style="width: 310px">
            {{ filterName(formData.rate2) }}
          </div>
        </div>
        <div class="view__group">
          <label class="view__label" style="width: auto"
            >客户指定汇率(欧元)</label
          >
          <div class="view__input" style="width: 310px">
            {{ filterName(formData.rate3) }}
          </div>
        </div>
      </div>
      <div v-show="!isEdit" class="update-time">
        更新时间：{{ filterName(formData.updateTime) }}
      </div>
      <!-- 编辑参数 -->
      <div v-show="isEdit">
        <el-form
          ref="form"
          label-position="top"
          :show-message="true"
          :rules="rules"
          :model="formData"
        >
          <div class="mali-edit__form">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="客户指定汇率(美元)" prop="rate1">
                  <el-input
                    v-model="formData.rate1"
                    clearable
                    :maxlength="5"
                    class="mali-full__input"
                    placeholder="请输入客户指定汇率(美元)"
                    @blur="changeRate($event, 'rate1')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="客户指定汇率(澳元)" prop="rate2">
                  <el-input
                    v-model="formData.rate2"
                    clearable
                    :maxlength="5"
                    class="mali-full__input"
                    placeholder="请输入客户指定汇率(澳元)"
                    @blur="changeRate($event, 'rate2')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="客户指定汇率(欧元)" prop="rate3">
                  <el-input
                    v-model="formData.rate3"
                    clearable
                    :maxlength="5"
                    class="mali-full__input"
                    placeholder="请输入客户指定汇率(欧元)"
                    @blur="changeRate($event, 'rate3')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div
        class="crm-button__group"
        v-if="!isEdit && hasPermission('crm:simple:putConsValueIncache')"
      >
        <button class="crm-form__button primary" @click="edit">编辑参数</button>
      </div>
      <div class="crm-button__group" v-if="isEdit">
        <button class="crm-form__button primary" @click="submit">保存</button>
        <button class="crm-form__button cancel" @click="cancel">取消</button>
      </div>
    </div>
  </CrmFormLayout>
</template>
<script>
export default {
  props: {
    DetailsData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑 3 在编辑 4 审核
      type: [String, Number],
      default: '',
    },
  },
  components: {},
  data() {
    return {
      message: '保存成功',
      isEdit: false,
      formData: {
        rate1: null,
        rate2: null,
        rate3: null,
        updateTime: null,
      },
      rules: {
        rate1: [
          {
            required: true,
            message: '请输入客户指定汇率(美元)',
            trigger: 'blur',
          },
        ],
        rate2: [
          {
            required: true,
            message: '请输入客户指定汇率(澳元)',
            trigger: 'blur',
          },
        ],
        rate3: [
          {
            required: true,
            message: '请输入客户指定汇率(欧元)',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getRateData();
  },
  computed: {
    loading() {
      return this.$store.getters.ajaxLoading;
    },
    comTitle() {
      if (this.isEdit) {
        return '编辑参数';
      } else {
        return '参数设置';
      }
    },
  },
  methods: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
    edit() {
      this.$emit('change', true);
      this.isEdit = true;
    },
    getRateData() {
      this.ajax({
        type: 'GET',
        url: 'malicrm/common/getPrescribedRate',
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            const data = res.result;
            this.formData.rate1 = data.rate[1];
            this.formData.rate2 = data.rate[2];
            this.formData.rate3 = data.rate[3];
            this.formData.updateTime = data.updateTime;
          }
        },
      });
    },
    changeRate(e, name) {
      // 格式化汇率格式
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      this.formData[name] = value;
    },
    cancel() {
      this.$emit('close');
    },
    /**
     * 保存
     */
    submit() {
      // 提交表单
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.formData);
          const data = {
            1: params.rate1,
            2: params.rate2,
            3: params.rate3,
          };
          this.ajax({
            type: 'POST',
            url: 'malicrm/common/editPrescribedRate',
            data: {
              value: JSON.stringify(data),
            },
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.successTip(this.message, 2000);
                this.$emit('close');
                this.$emit('change', false);
                this.isEdit = false;
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.update-time {
  color: #f06767;
  font-size: 14px;
  text-align: right;
  margin-top: -8px;
}
</style>
