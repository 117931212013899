import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57b4409c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-contanier" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpHeader = _resolveComponent("ErpHeader")!
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_ErpBread = _resolveComponent("ErpBread")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ErpHeader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SideBar),
      _createElementVNode("div", {
        class: _normalizeClass(["erp-body", { 'crm-body': !_ctx.isErp }]),
        style: _normalizeStyle({ left: _ctx.leftWidth })
      }, [
        (_ctx.isErp)
          ? (_openBlock(), _createBlock(_component_ErpBread, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view)
      ], 6)
    ])
  ]))
}