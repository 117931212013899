<template>
  <div class="">
    <el-form :model="productForm" :class="isSingle">
      <el-upload
        :limit="maxImg"
        :headers="token"
        :action="uploadImageUrl"
        :data="uploadRequest"
        :file-list="fileUrlsArray"
        :accept="fileType"
        :before-upload="beforeAvatarUpload"
        :on-success="checkSuccess"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :show-file-list="false"
        :on-progress="handleProcess"
        ref="upload"
      >
        <el-button size="small" type="primary">修改头像</el-button>
      </el-upload>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    maxImg: {
      // 最大上传个数
      type: Number,
      default: 1,
    },
    queryNum: {
      // 根据这个变量 查找 第几个上传
      type: Number,
    },
    titleImg: {
      type: String,
    },
    fileType: {
      type: String,
    },
    fileUrlsArray: {
      type: Array,
    },
    fromType: {
      type: Number,
    },
    isSize: {
      type: Object,
      default: function () {
        return {
          isSize: false,
          width: 1080,
          height: 768,
        };
      },
    },
  },
  data() {
    return {
      imageNameList: [],
      imageUrlList: [],
      editor: {},
      token: { Authorization: 'Bearer ' + this.$store.getters.getAccessToken },
      uploadImageUrl: process.env.VUE_APP_UPLOAD_FILE, // 'http://upload.malicn.com/uploadFile/', 'http://172.31.10.31:9001/fastdfs/upload'
      dialogImageUrl: '',
      dialogVisible: false,
      pdfVisible: false,
      productForm: {
        status: 1,
      },
      uploadRequest: {},
      uploadIsPic: null,
    };
  },
  methods: {
    handelExceedFn() {
      this.errorTip('图片数量过多');
    },
    beforeAvatarUpload(file) {
      this.$emit('change', []);
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const titleIndex = file.name.indexOf('.');
      const titleLen = file.name.slice(0, titleIndex);
      if (titleLen.length > 30) {
        this.errorTip('文件名过长，请修改');
        return false;
      }
      if (!isJPG && !isJPEG && !isGIF && !isPNG && !isBMP) {
        this.errorTip('上传图片必须是JPG/GIF/PNG/BMP 格式!');
        return false;
      }
      if (!isLt2M) {
        this.errorTip('文件过大');
        return false;
      }
      if (this.isSize.isSize) {
        const isSize = new Promise(function (resolve, reject) {
          const width = this.isSize.width;
          const height = this.isSize.height;
          const _URL = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = function () {
            const valid = img.width <= width && img.height <= height;
            /* eslint-disable */
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(() => {
          return file;
        }, () => {
          this.errorTip('The size limit is ' + that.isSize.width + 'px in length and ' + that.isSize.height + 'px in width');
          return Promise.reject();
        });
        return isSize;
      }
      return isLt2M;
    },
    handlePictureCardPreview(file, $event) {
      this.dialogImageUrl = file.url;
      let fileExtension = '';
      if (file.response == null) {
        fileExtension = file.url.substring(file.url.lastIndexOf('.') + 1);
      } else {
        fileExtension = file.response.data.substring(file.response.data.lastIndexOf('.') + 1);
      }
      if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'png' || fileExtension === 'gif' || fileExtension === 'bmp') {
        this.dialogVisible = true;
        this.pdfVisible = false;
      }
      if (fileExtension === 'pdf') {
        let link = document.createElement('a');
        link.style.display = 'none';
        window.open(this.dialogImageUrl);
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
      }
      if (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'doc' || fileExtension === 'docx' || fileExtension === 'csv') {
        this.exportFileFileType({
          type: 'POST',
          url: '/maliwms/file/downloadFile',
          data: { url: file.url, name: file.name },
          loading: true,
          fileName: file.name
        });
      }
    },
    checkSuccess(response, file, fileList) {
      console.log(response);
      if (response.code !== 200) {
        this.errorTip(response.msg);
        fileList.splice(fileList.indexOf(file), 1);
      
      } else {
          var fileType = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (fileType == 'jpg' || fileType == 'png' || fileType == 'gif' || fileType == 'bmp' || fileType == 'jpeg' || fileType == 'JPG' || fileType == 'JPEG') {
        this.uploadIsPic = true;
      } else {
        this.uploadIsPic = false;
      }
      this.imageNameList = [];
      this.imageUrlList = [];
      this.productForm.attachmentIdList = [];
      fileList.forEach((item, index) => {
        this.imageNameList.push(item.name);
        if (item.response == null) {
          this.imageUrlList.push(item.url);
        } else {
          this.imageUrlList.push(item.response.result.url);
        }
      });
      this.$emit('getFileUrls', this.$props.titleImg, this.imageNameList, this.imageUrlList);
      this.$refs.upload.clearFiles()
      }
    
    },
    handleRemove(file, fileList) {
      this.productForm.attachmentIdList = [];
      this.imageNameList = [];
      this.imageUrlList = [];
      fileList.forEach((item) => {
        this.imageNameList.push(item.name);
        if (item.response == null) {
          this.imageUrlList.push(item.url);
        } else {
          this.imageUrlList.push(item.response.data);
        }
      });
      this.$emit('getFileUrls', this.$props.titleImg, this.imageNameList, this.imageUrlList);
    },
    handleProcess(event, file, fileList) {

    }
  },
  watch: {
    imageUrlList() {
      if (this.imageUrlList.length === this.$props.maxImg) {

      } else {

      }
    },
    fileUrlsArray() {
      if (this.$props.maxImg === this.$props.fileUrlsArray.length) {

      } else {
      }
    }
  },
  computed: {
    isSingle() {
      var single = '';
      if (this.imageUrlList.length === this.$props.maxImg || this.$props.maxImg === this.$props.fileUrlsArray.length) {
        single = 'single';
      }
      return single;
    }
  }
};
</script>
<style scoped>
i.icon {
  color: #ff6c2b;
  font-size: 22px;
}

.single .el-upload--picture-card {
  display: none !important;
}



.el-form.single
  .ispic
  .el-upload-list--picture-card
  .el-upload-list__item-name {
  display: none !important;
}
</style>
