<template>
  <div class="erp-view__inner" :class="{ 'is-edit': type === 'edit' }">
    <div
      class="erp-view__label"
      :style="{ width: labelwidth + 'px' }"
      v-if="type !== 'txt'"
      v-show="showLabel"
    >
      {{ label }}
    </div>
    <slot>
      <div class="erp-view__value">{{ fiterValue(value) }}</div>
    </slot>
    <slot name="btn"></slot>
  </div>
</template>
<script>
export default {
  name: 'ErpView',
  props: {
    showLabel: {
      type: [Boolean],
      default: true,
    },
    type: {
      //  默认取值 ''  'txt' 'edit'
      type: [String],
      default: '',
    },
    label: {
      type: [String],
      default: 'label',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    labelwidth: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    fiterValue(value) {
      if (value === '' || value === null) {
        return '-';
      } else {
        return value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pd-30 .erp-view__inner {
  padding: 25px 0 12px 0;
}

</style>
