
import { useStore } from '@/store';
import { ConsKey, ConsType, ISelectItem } from '@/types/type';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'DictSelect',
  props: {
    name: {
      type: String as PropType<ConsKey>,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    customOption: {
      type: Array,
    },
    /** 这种属性值最好叫key */
    keyLabel: {
      type: String as PropType<string>,
      default: 'label',
    },
    keyValue: {
      type: String as PropType<string>,
      default: 'value',
    },
    keyLabelOpt: {
      type: String as PropType<string>,
      default: 'label',
    },
    keyValueOpt: {
      type: String as PropType<string>,
      default: 'value',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:value', 'change'],
  setup(props, ctx) {
    const dictSelectRef = ref();
    const store = useStore();
    const modelValue = ref();
    const dictOptions = computed(() => {
      return store.state.dict?.options;
    });
    const options = computed(() => {
      const dict = dictOptions.value;
      if (props.customOption && props.customOption.length > 0) {
        const transOption: ISelectItem[] = [];
        (props.customOption as any).forEach((item) => {
          if (props.keyLabel) {
            transOption.push({
              label: item[props.keyLabel],
              value: item[props.keyValue],
            });
          } else {
            // 兼容没有key,value的情况
            transOption.push({ label: item, value: item });
          }
        });

        return transOption;
      } else {
        return (dict as ConsType)[props.name as string];
      }
    });
    const customBlur = () => {
      dictSelectRef.value.blur();
    };
    const getModelValue = (val) => {
      ctx.emit('update:value', val);
      ctx.emit('change', val);
    };

    return { options, getModelValue, modelValue, dictSelectRef, customBlur };
  },
});
