<template>
  <div>
    <teleport to="body">
      <div class="dialog-wrap" :class="{ 'erp-dialog__warp': erp }">
        <div class="dialog-cover" v-if="visible" @click.stop="closeMyself"></div>
        <transition name="drop">
          <div class="dialog-content" :style="[dialogSize]" v-if="visible" :class="{ 'erp-dialog__warp': erp }">
            <div class="top-title" :style="{ width: dialogSize.width }" v-if="!erp">
              <span>{{ title }}</span>
              <span class="dialog-close el-icon-close" @click.stop="closeMyself"></span>
            </div>
            <div class="content-scroll-box" :style="{ width: dialogSize.width }">
              <div class="dialog-body" :class="{ 'erp-scroll-box': erp }">
                <slot></slot>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: 'ErpDrawer',
  props: {
    erp: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '1000',
    },
  },
  emits: ['close'],
  data() {
    return {
      dialogSize: {
        width: this.width + 'px',
      },
    };
  },
  watch: {
    visible: {
      handler() {
        if (this.visible) {
          document.querySelector('body').style.overflowY = 'hidden';
        } else {
          document.querySelector('body').style.overflowY = 'auto';
        }
      },
      immediate: true,
    },
    width(n,o){
      console.log("🚀 ~ file: ErpDrawer.vue ~ line 65 ~ width ~ n,o", n,o);
      this.dialogSize.width = n + 'px';
    }
  },
  methods: {
    closeMyself() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-enter-active {
  transition: all 0.3s ease;
}
.drop-leave-active {
  transition: all 0.3s ease;
}
.drop-enter {
  transform: translateX(1000px);
}
.drop-leave-active {
  transform: translateX(1000px);
}

.dialog-wrap {
  text-align: left;
  position: fixed;
  width: 1px;
  height: 100%;
  z-index: 1000;
  right: 0;
}
.dialog-cover {
  background: #000;
  opacity: 0.4;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body .top-title {
  background: #fff;
  border-bottom: 1px solid #e3e4e6;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #333;
  padding: 0 20px;
  width: 100%;
}

body .top-title span {
  font-size: 16px;
}

body span.dialog-close {
  float: right;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
  font-size: 20px;
}

body .dialog-close:hover {
  color: #4fc08d;
}
.dialog-content {
  width: 1000px;
  position: fixed;
  height: 100vh;
  background: #f5f6f7;
  top: 0;
  right: 0;
  z-index: 2000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.dialog-body {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  overflow: auto;
  width: 100%;
}
.dialog-body::-webkit-scrollbar {
  display: none;
}

.content-scroll-box {
  height: 100%;
  width: 960px !important;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 20px;
}
.content-scroll {
  height: 100%;
  width: 976px;
  overflow-y: scroll;
}

.erp-dialog__warp {
  background: #fff;
}
.erp-scroll-box {
  top: 0;
}
.no-padding .dialog-body {
  padding: 20px 0;
}
.fix-top-header .dialog-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
