import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import { RootStoreState } from '../types/store';
import userModule from './modules/user/index';
import browserModule from './modules/browser';
import dictModule from './modules/dict/index';
import tableModule from './modules/table/index';
export const store = createStore<RootStoreState>({
  modules: {
    user: userModule,
    browser: browserModule,
    dict: dictModule,
    table: tableModule,
  },
});
// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<RootStoreState>> = Symbol('vue-store');
export function useStore<T = RootStoreState>() {
  return baseUseStore<T>(key);
}
