export default [
  {
    meta: {
      name: '线索列表',
      activePath: '/clue/clueList',
      activeIndex: 0,
    },
    path: '/clue/clueList', // 线索列表
    name: 'ClueList',
    component: () => import('../views/crm/clue/clueList.vue'),
  },
  {
    meta: {
      name: '线索池列表',
      activePath: '/clue/clueList',
      activeIndex: 0,
    },
    path: '/clue/cluepool', // 线索池列表
    name: 'Cluepool',
    component: () => import('../views/crm/clue/cluepool.vue'),
  },
  {
    meta: {
      name: '线索回收站列表',
      activePath: '/clue/clueList',
      activeIndex: 0,
    },
    path: '/clue/recyclebin', // 线索回收站列表
    name: 'ClueRecyclebin',
    component: () => import('../views/crm/clue/recyclebin.vue'),
  },
  {
    meta: {
      name: '线索详情',
      activePath: '/clue/clueList',
      activeIndex: 0,
    },
    path: '/clue/viewClue', // 线索回收站列表
    name: 'viewClue',
    component: () => import('../views/crm/clue/viewClue.vue'),
  },
  {
    meta: {
      name: '客户列表',
      activePath: '/client/clientList',
      activeIndex: 1,
    },
    path: '/client/clientList', // 客户列表
    name: 'ClientList',
    component: () => import('../views/crm/client/clientList.vue'),
  },
  {
    meta: {
      name: '客户池列表',
      activePath: '/client/clientList',
      activeIndex: 1,
    },
    path: '/client/clientpoop', // 客户池列表
    name: 'Clientpoop',
    component: () => import('../views/crm/client/clientpoop.vue'),
  },
  {
    meta: {
      name: '客户回收站列表',
      activePath: '/client/clientList',
      activeIndex: 1,
    },
    path: '/client/recycleBin', // 客户回收站列表
    name: 'ClientRecycleBin',
    component: () => import('../views/crm/client/recycleBin.vue'),
  },
  {
    meta: {
      name: '客户详情',
      activePath: '/client/clientList',
      activeIndex: 1,
    },
    path: '/client/contactDetails', // 客户详情
    name: 'ContactDetails',
    component: () => import('../views/crm/client/contactDetails.vue'),
  },
  {
    meta: {
      name: '联系人列表',
      activePath: '/contacts/contactsList',
      activeIndex: 2,
    },
    path: '/contacts/contactsList', // 联系人列表
    name: 'ContactsList',
    component: () => import('../views/crm/contacts/contactsList.vue'),
  },
  {
    meta: {
      name: '联系人回收站列表',
      activePath: '/contacts/contactsList',
      activeIndex: 2,
    },
    path: '/contacts/recyclebin', // 联系人回收站列表
    name: 'Recyclebin',
    component: () => import('../views/crm/contacts/recyclebinList.vue'),
  },
  {
    meta: {
      name: '报盘列表',
      activePath: '/offer/offerList',
      activeIndex: 3,
    },
    path: '/offer/offerList', // 报盘列表
    name: 'OfferList',
    component: () => import('../views/crm/offer/offerList.vue'),
  },
  {
    meta: {
      name: '报盘详情',
      activePath: '/offer/offerList',
      activeIndex: 3,
    },
    path: '/offer/offerDetails', // 报盘详情
    name: 'OfferDetails',
    component: () => import('../views/crm/offer/offerDetails.vue'),
  },
  {
    meta: {
      name: '商机列表',
      activePath: '/opportunity/opportunityList',
      activeIndex: 4,
    },
    path: '/opportunity/opportunityList', // 商机列表
    name: 'OpportunityList',
    component: () => import('../views/crm/opportunity/opportunityList.vue'),
  },
  {
    meta: {
      name: '商机回收站列表',
      activePath: '/opportunity/opportunityList',
      activeIndex: 4,
    },
    path: '/opportunity/opportinuityRecycleList', // 商机回收站列表
    name: 'OpportinuityRecycleList',
    component: () =>
      import('../views/crm/opportunity/opportinuityRecycleList.vue'),
  },
  {
    meta: {
      name: '商机详情',
      activePath: '/opportunity/opportunityList',
      activeIndex: 4,
    },
    path: '/opportunity/opportunityDetail', // 商机详情
    name: 'OpportunityDetail',
    component: () => import('../views/crm/opportunity/opportunityDetail.vue'),
  },
  {
    meta: {
      name: '任务列表',
      activePath: '/office/officeList',
      activeIndex: 5,
    },
    path: '/office/officeList', // 任务列表
    name: 'OfficeList',
    component: () => import('../views/crm/office/officeList.vue'),
  },
  {
    meta: {
      name: '资方框架列表',
      activePath: '/documents/docFunFrameworkList',
      activeIndex: 6,
    },
    path: '/documents/docFunFrameworkList', // 资方框架列表
    name: 'DocFunFrameworkList',
    component: () =>
      import('../views/crm/documents/funFramework/funFrameworkList.vue'),
  },
  {
    meta: {
      name: '客户框架列表',
      activePath: '/documents/docCusFrameworkList',
      activeIndex: 6,
    },
    path: '/documents/docCusFrameworkList', // 客户框架列表
    name: 'DocCusFrameworkList',
    component: () =>
      import('../views/crm/documents/cusFramework/cusFrameworkList.vue'),
  },
  {
    meta: {
      name: '订单合同列表',
      activePath: '/documents/documentsList',
      activeIndex: 6,
    },
    path: '/documents/documentsList', // 订单合同列表
    name: 'DocumentsList',
    component: () => import('../views/crm/documents/order/orderDocList.vue'),
  },
  {
    meta: {
      name: '工厂列表',
      activePath: '/factory/index',
      activeIndex: 7,
    },
    path: '/factory/index', // 品种列表
    name: 'Factory',
    component: () => import('../views/crm/basicInformation/factory/index.vue'),
  },
  {
    meta: {
      name: '品种列表',
      activePath: '/basicInformation/varieties',
      activeIndex: 7,
    },
    path: '/basicInformation/varieties', // 品种列表
    name: 'Varieties',
    component: () =>
      import('../views/crm/basicInformation/varieties/varietiesList.vue'),
  },
  {
    meta: {
      name: '品种列表',
      activePath: '/label/labelIndex',
      activeIndex: 7,
    },
    path: '/label/labelIndex', // 品名列表
    name: 'Label',
    component: () =>
      import('../views/crm/basicInformation/label/labelList.vue'),
  },
  {
    meta: {
      name: '件套列表',
      activePath: '/label/labelIndex',
      activeIndex: 7,
    },
    path: '/basicInformation/Piececover', // 件套列表
    name: 'Piececover',
    component: () =>
      import('../views/crm/basicInformation/piececover/piececoverList.vue'),
  },
  {
    meta: {
      name: '税率列表',
      activePath: '/basicInformation/taxrate',
      activeIndex: 7,
    },
    path: '/basicInformation/taxrate', // 税率列表
    name: 'Taxrate',
    component: () =>
      import('../views/crm/basicInformation/taxrate/taxrateList.vue'),
  },
  {
    meta: {
      name: '供应商列表',
      activePath: '/basicInformation/supplier',
      activeIndex: 7,
    },
    path: '/basicInformation/supplier', // 供应商列表
    name: 'Supplier',
    component: () =>
      import('../views/crm/basicInformation/supplier/supplierList.vue'),
  },
  {
    meta: {
      name: '供应商列表',
      activePath: '/basicInformation/supplier',
      activeIndex: 7,
    },
    path: '/basicInformation/supplierDetails', // 供应商详情
    name: 'SupplierDetails',
    component: () =>
      import('../views/crm/basicInformation/supplier/supplierDetails.vue'),
  },
  {
    meta: {
      name: '资金方列表',
      activePath: '/basicInformation/funding',
      activeIndex: 7,
    },
    path: '/basicInformation/funding', // 资金方列表
    name: 'Funding',
    component: () =>
      import('../views/crm/basicInformation/funding/fundingList.vue'),
  },
  {
    meta: {
      name: '资金方详情',
      activePath: '/basicInformation/funding',
      activeIndex: 7,
    },
    path: '/basicInformation/fundingDetail', // 资金方详情
    name: 'FundingDetail',
    component: () =>
      import('../views/crm/basicInformation/funding/fundingDetail.vue'),
  },
  {
    meta: {
      name: '占用额度',
      activePath: '/basicInformation/funding',
      activeIndex: 7,
    },
    path: '/basicInformation/occupy', // 占用额度-列表
    name: 'OccupyList',
    component: () =>
      import('../views/crm/basicInformation/funding/occupyList.vue'),
  },
  {
    meta: {
      name: '抬头列表',
      activePath: '/basicInformation/rise',
      activeIndex: 7,
    },
    path: '/basicInformation/rise', // 抬头列表
    name: 'Rise',
    component: () => import('../views/crm/basicInformation/rise/riseList.vue'),
  },
  {
    meta: {
      name: '冷库列表',
      activePath: '/basicInformation/storage',
      activeIndex: 7,
    },
    path: '/basicInformation/storage', // 冷库列表
    name: 'Storage',
    component: () =>
      import('../views/crm/basicInformation/storage/storageList.vue'),
  },
  {
    meta: {
      name: '口岸列表',
      activePath: '/basicInformation/port',
      activeIndex: 7,
    },
    path: '/basicInformation/port', // 口岸列表
    name: 'port',
    component: () => import('../views/crm/basicInformation/port/portList.vue'),
  },
  {
    meta: {
      name: '代理列表',
      activePath: '/basicInformation/agent',
      activeIndex: 7,
    },
    path: '/basicInformation/agent', // 代理列表
    name: 'Agent',
    component: () =>
      import('../views/crm/basicInformation/agent/agentList.vue'),
  },
  {
    meta: {
      name: '船公司列表',
      activePath: '/basicInformation/shippingCompany',
      activeIndex: 7,
    },
    path: '/basicInformation/shippingCompany', // 船公司列表
    name: 'ShippingCompany',
    component: () =>
      import(
        '../views/crm/basicInformation/shippingCompany/shippingCompanyList.vue'
      ),
  },
  {
    meta: {
      name: '报单记录',
      activePath: '/crm/declare/list',
      activeIndex: 7,
    },
    path: '/crm/declare/list', // 报单记录
    name: 'declareList',
    component: () => import('../views/crm/declare/list.vue'),
  },
  {
    meta: {
      name: '报单详情',
      activePath: '/crm/declare/list',
      activeIndex: 4,
    },
    path: '/crm/declare/detail', // 报单详情
    name: 'declareDetail',
    component: () => import('../views/crm/declare/detail.vue'),
  },
];
