import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5794b9db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "crm-tree__warp" }
const _hoisted_3 = {
  class: "crm-button__group",
  style: {"padding":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_input, {
      modelValue: _ctx.nodeNames,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nodeNames) = $event)),
      readonly: true,
      onClick: _ctx.showVisible,
      disabled: _ctx.disabled,
      "suffix-icon": !_ctx.visible ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
      placeholder: _ctx.placeholder
    }, null, 8, ["modelValue", "onClick", "disabled", "suffix-icon", "placeholder"]), [
      [_vShow, !_ctx.isHidden]
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.titleLabel,
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      onClose: _ctx.closeVisible,
      onOpened: _ctx.openDialog,
      "append-to-body": true,
      ref: "dialogRef",
      "custom-class": "chooseManDialog border",
      width: "550px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tree, {
            data: _ctx.treeData,
            "show-checkbox": "",
            "node-key": "id",
            "default-expanded-keys": _ctx.nodeIds,
            "default-checked-keys": _ctx.nodeIds,
            props: _ctx.defaultProps,
            ref: "treeRef"
          }, null, 8, ["data", "default-expanded-keys", "default-checked-keys", "props"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "crm-form__button primary",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveDeptData && _ctx.saveDeptData(...args)))
          }, "保存"),
          _createElementVNode("button", {
            class: "crm-form__button cancel",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, "取消")
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onClose", "onOpened"])
  ]))
}