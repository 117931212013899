<template>
  <scroll-pane ref="scrollPane" class="tags-view-wrapper">
    <div class="erp-bread">
      <ul>
        <li v-for="(item, index) in breadList" :key="item.fullPath" :class="{ active: item.fullPath === activePath }" @click="tabPath(item)">
          <span class="route-name">{{ getName(item) }}</span>
          <span style="padding: 0 0 0 8px; height: 32px; line-height: 32px" @click.stop="close(item, index)">
            <i class="el-icon-close"></i>
          </span>
        </li>
      </ul>
    </div>
  </scroll-pane>
</template>
<script>
import ScrollPane from './ScrollPane.vue';
export default {
  name: 'ErpBread',
  components: {
    ScrollPane,
  },
  data() {
    return {
      activePath: '',
    };
  },
  computed: {
    breadList() {
      return this.$store.state.browser.breadList;
    },
  },
  methods: {
    getName(item) {
      // 获取路由名称
      if (item.meta && item.meta.name !== '') {
        return item.meta.name;
      } else {
        return '菜单';
      }
    },
    close(item, index) {
      if (this.breadList.length > 1) {
        this.$store.commit('delBreadList', index);
        const params = this.breadList[this.breadList.length - 1];
        if (this.breadList.length <= 0) {
          return false;
        } else {
          this.$router.push(params.fullPath);
        }
      }
    },
    tabPath(item) {
      this.activePath = item.fullPath;
      this.$router.push(item.fullPath);
    },
    setBread() {
      const params = this.$route;
      const filterData = this.breadList.filter((v) => {
        return v.fullPath === this.$route.fullPath;
      });
      if (filterData.length <= 0) {
        this.$store.commit('setBreadList', params);
      } else {
        // 其他判断
      }
      this.activePath = params.fullPath;
    },
  },
  watch: {
    $route: {
      handler() {
        this.setBread();
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.tags-view-wrapper {
  height: 36px;
}
.erp-bread {
  width: 100%;
  background: #fff;
  height: 36px;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #e8ecf6;
  ul {
    display: flex;
  }
  li {
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    border-right: 1px solid #e8ecf6;
    border-bottom: 1px solid #e8ecf6;
    display: flex;
    align-items: center;
    color: #909399;
    cursor: pointer;
    &.active {
      color: #2878ff;
      border-bottom: 1px solid #fff;
    }
    .el-icon-close:hover {
      color: #2878ff;
    }
    .route-name {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}
</style>
