import { store } from '@/store';

export default {
  install: (app) => {
    // Plugin code goes here
    app.directive('permission', {
      mounted(el, binding) {
        const permissions = store.getters.getPermissions;
        let result = 0;
        for (const permission of permissions) {
          for (const bind of binding.value) {
            if (permission === bind) {
              result += 1;
            }
          }
        }
        if (result > 0) {
          el.style.display = '';
        } else {
          el.style.display = 'none';
        }
      },
    });
  },
};
