
import { defineComponent, onMounted, provide } from 'vue';
import { httpPost } from './api';
import useLoading from './hooks/useLoading';
import { useStore } from './store';
export default defineComponent({
  setup() {
    // 注入loading
    provide('loading', useLoading());
    const store = useStore();
    const getMenuList = () => {
      // 获取菜单信息
      httpPost('/uac/user/loginAfter/1').then((res) => {
        if (res.code === 200) {
          store.dispatch('setDepartment', res.result);
          store.dispatch('setUserInfo', res.result).then();
        }
      });
    };
    const setTitle = () => {
      const title = document.title;
      const location = window.location.hostname;
      if (process.env.NODE_ENV === 'production') {
        // 生产环境
        if (location.indexOf('saasweb.malicn.com') > -1) {
          document.title = '开发-' + title;
        }
        if (location.indexOf('lgsaas.malicn.com') > -1) {
          document.title = '预生产-' + title;
        }
      } else {
        if (title.indexOf('本地') < 0) {
          document.title = 'v3本地-' + title;
        }
      }
    };
    getMenuList();
    onMounted(() => {
      setTitle();
    });
  },
});
