
import router from '@/router';
import { useStore } from '@/store';
import { CurrentMenu } from '@/types/type';
import { computed, defineComponent, ref, watch } from 'vue';
import useVisible from '@/hooks/useVisible';
import { erpConfirm, errorMessage, loadPage, hasMenu } from '@/utils/publicMethods';
import UserInfo from '@/views/crm/system/userInfo.vue';
import SetParams from '@/views/crm/system/setParams/form.vue';
import CuleForm from '@/views/crm/clue/components/ClueForm/ClueForm.vue';
import ClientForm from '@/views/crm/client/components/ClueForm/ClueForm.vue';
import OpportunityForm from '@/views/crm/opportunity/components/OpportunityForm/OpportunityForm.vue';
import { httpPost } from '@/api';
export default defineComponent({
  components: { UserInfo, SetParams, CuleForm, ClientForm, OpportunityForm },
  props: {},
  data() {
    return {
      addNewDialog: {
        show: false,
        title: '',
        type: null,
      },
      taskTimer: null,
    };
  },
  setup() {
    const store = useStore();
    const currentMenu = ref('CRM');
    const { visible, showVisible, formType, setFormType, closeVisible } = useVisible();
    const searchList = ref([]);
    const queryString = ref('');
    const isHiddenCache = computed(() => {
      return store.state.user?.userId !== '1';
    });
    const taskCount = computed(() => {
      return store.state.user?.taskCount.taskCount;
    });
    const dropDownList = computed(() => {
      if (currentMenu.value === 'CRM') {
        return [
          {
            name: '个人资料',
            command: 'userInfo',
          },
          {
            name: '组织架构',
            command: 'frameList',
            isHidden: !hasMenu('/system/frameList'),
          },
          {
            name: '权限分配',
            command: 'permission',
            isHidden: !hasMenu('/system/permission'),
          },
          {
            name: '客户账号',
            command: 'AccountList',
            isHidden: !hasMenu('/system/accountList'),
          },
          {
            name: '参数设置',
            command: 'setParams',
            isHidden: !hasMenu('/crm/setParams'),
          },
          {
            name: '菜单管理',
            command: 'menuManage',
            isHidden: !hasMenu('/system/menuManage'),
          },
          {
            name: '权限管理',
            command: 'permissionList',
            isHidden: !hasMenu('/system/permissionList'),
          },
          {
            name: '缓存管理',
            command: 'cacheIndex',
            isHidden: isHiddenCache.value,
          },
          {
            name: '注销登录',
            command: 'logout',
          },
        ];
      } else {
        return [
          {
            name: '个人资料',
            command: 'userInfo',
          },
          {
            name: '注销登录',
            command: 'logout',
          },
        ];
      }
    });
    const headImage = computed(() => {
      return store.state.user?.headImage;
    });
    const userName = computed(() => {
      return store.state.user?.userName;
    });
    const querySearchAsync = async (queryString, cb) => {
      const params = {
        queryString,
      };
      const res = await httpPost('/malicrm/common/indexSearch', params);
      if (res.code === 200) {
        searchList.value = res.result;
        if (searchList.value.length > 0) {
          searchList.value.forEach((item) => {
            (item as any).value = (item as any).content;
          });
        }
        cb(searchList.value);
      } else {
        errorMessage(res.message);
      }
    };

    const handleSelect = (item) => {
      if (!item.hasPermission) {
        errorMessage('无权查看');
      }
      let detailType = 0;
      const currentPath = router.currentRoute.value.path;
      // 线索
      if (item.type === 1) {
        if (item.isInPool) {
          detailType = 2;
        } else {
          detailType = 1;
        }
        if (item.isTransformed) {
          detailType = 4;
        }
        loadPage('viewClue', {
          id: item.id,
          type: detailType,
          backFrom: currentPath,
        });
      }
      // 客户
      if (item.type === 2) {
        if (item.isInPool) {
          detailType = 2;
        } else {
          detailType = 1;
        }
        loadPage('ContactDetails', {
          id: item.id,
          type: detailType,
          isRecycle: false,
          backFrom: currentPath,
        });
      }
      // 商机
      if (item.type === 3) {
        if (item.isInPool) {
          detailType = 2;
        } else {
          detailType = 1;
        }
        loadPage('OpportunityDetail', {
          id: item.id,
          type: detailType,
          isRecycle: false,
          backFrom: currentPath,
        });
      }
      queryString.value = '';
    };
    const quickLink = (command) => {
      if (command === 'clue') {
        setFormType(1);
      }
      if (command === 'client') {
        setFormType(2);
      }
      if (command === 'opportunity') {
        setFormType(3);
      }
      showVisible();
    };
    watch(
      () => store.state.user?.currentMenu,
      (newVal) => {
        currentMenu.value = newVal as CurrentMenu;
        if (newVal === 'ERP') {
          // 如果是erp的话就触发一次任务刷新
          store.dispatch('getTaskCount');
        }
      },
      { immediate: true }
    );

    const changeMenu = (name) => {
      currentMenu.value = name;
      store.dispatch('changeMenu', name).then(() => {
        store.commit('clearBreadList');
        store.commit('clearActiveIndexs');
        // todo展示内容数据
        if (name === 'CRM') {
          router.push('/clue/clueList');
        } else {
          router.push('/erp/taskList');
        }
      });
    };
    const logOut = () => {
      // 退出登录操作
      store.dispatch('clearUserInfo').then(() => {
        store.commit('clearBreadList'); // 请
        router.push('/login');
      });
    };
    const rightInfo = (commond) => {
      switch (commond) {
        case 'userInfo':
          setFormType(4);
          showVisible();
          break;
        case 'frameList':
        case 'permission':
        case 'AccountList':
        case 'permissionList':
        case 'menuManage':
        case 'cacheIndex':
          loadPage(commond);
          break;
        case 'setParams':
          setFormType(5);
          showVisible();
          break;
        case 'logout':
          erpConfirm('你确定退出登录么?')
            .then(() => {
              logOut();
            })
            .catch();
          break;
        default:
          break;
      }
    };

    const toTaskPage = () => {
      const url = '/erp/taskList';
      const isIn = ~window.location.href.indexOf(url);
      if (isIn) {
        return;
      }
      router.push({
        path: url,
      });
    };
    return {
      visible,
      showVisible,
      formType,
      setFormType,
      toTaskPage,
      currentMenu,
      changeMenu,

      dropDownList,
      taskCount,
      isHiddenCache,
      rightInfo,
      headImage,
      userName,
      logOut,
      closeVisible,
      querySearchAsync,
      searchList,
      queryString,
      handleSelect,
      quickLink,
    };
  },
});
