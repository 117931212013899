import { httpPost } from '@/api';
import { DictState, RootStoreState } from '@/types/store';
import { setDictOptions } from '@/utils/publicMethods';

import { Module } from 'vuex';
const dictModule: Module<Partial<DictState>, RootStoreState> = {
  state: {
    departmentPersonTreeAll: [],
    departmentPersonTree: [],
    options: {},
  },
  getters: {},
  mutations: {
    SET_OPTIONS(state, options: any) {
      state.options = options;
    },
  },
  actions: {
    //缓存部门树组件信息,避免每次请求都去拿数据
    async setDepartment({ state }) {
      httpPost('/uac/department/getDepartmentPersonTreeAll').then((res) => {
        if (res.code === 200) {
          state.departmentPersonTreeAll = res.result;
        } else {
          state.departmentPersonTreeAll = [];
        }
      });
      httpPost('/uac/department/getDepartmentPersonTree').then((res) => {
        if (res.code === 200) {
          state.departmentPersonTree = res.result;
        } else {
          state.departmentPersonTree = [];
        }
      });
    },
    async getDepartmentPerson({ state }, selectAll) {
      if (selectAll) {
        return state.departmentPersonTreeAll;
      } else {
        return state.departmentPersonTree;
      }
    },
    async setDictOptions({ commit }) {
      const options = await setDictOptions();
      // 请求没有获取到就赋空
      if (Object.keys.length > 0) {
        commit('SET_OPTIONS', options);
        return true;
      } else {
        return false;
      }
    },
  },
};
export default dictModule;
