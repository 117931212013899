import { httpGet } from '@/api';
import { MenuState, RootStoreState } from '@/types/store';
import { CurrentMenu, ErpUseInfo } from '@/types/type';
import { errorMessage } from '@/utils/publicMethods';
import { Module } from 'vuex';

const userModule: Module<Partial<MenuState>, RootStoreState> = {
  state: {
    access_token: null, // 登录token
    refresh_token: null, // refashtoken
    loginName: null, // 登录用户,
    companyName: null,
    userId: null,
    headImage: '',
    userName: '',
    authCodeSet: null, // 菜单权限
    loginAuthDto: null, // 用户信息
    menuList: null, // 菜单信息
    crmMenuList: [], // crm菜单
    erpMenuList: [], // erp菜单
    menuNames: {},
    currentMenu: 'CRM', // 当前菜单
    currentMenuList: [], // 当前选中菜单列表
    permissions: [], // 权限字符串
    taskCount: {
      chargeCount: 0,
      initiateCount: 0,
      taskCount: 0,
    },
  },
  getters: {
    getPermissions: (state) => {
      const localePermissions = JSON.parse(localStorage.getItem('saasPermissions') as string);
      return state.permissions && state.permissions.length > 0 ? state.permissions : localePermissions;
    },
    getUserId: (state) => {
      const userId = JSON.parse(localStorage.getItem('userId') as string);
      return state.userId ? state.userId : userId;
    },
    getLoginName: (state) => {
      const loginName = localStorage.getItem('loginName') as any;
      return state.loginName ? state.loginName : loginName;
    },
    getHeadImage: (state) => {
      const headImage = localStorage.getItem('headImage') as any;
      return state.headImage ? state.headImage : headImage;
    },
  },
  mutations: {
    SET_TASK_COUNT(state, result) {
      state.taskCount = result;
    },
    CHANGE_MENU(state, type: CurrentMenu) {
      state.currentMenu = type;
      if (type === 'CRM') {
        state.currentMenuList = state.crmMenuList;
      } else {
        state.currentMenuList = state.erpMenuList;
      }
    },
    // 设置crm菜单
    SET_CRM_MENU_LIST(state, menuList) {
      let data = menuList.filter((v) => v.menuCode === 'CRM');
      if (data) {
        data = data[0].subMenu;
        data = data.filter((v) => v.menuName !== '主菜单');
        state.crmMenuList = data;
      } else {
        state.crmMenuList = [];
      }
    },
    // 设置erp菜单
    SET_ERP_MENU_LIST(state, menuList) {
      let data = menuList.filter((v) => v.menuCode === 'ERP');
      console.log(data);
      if (data && data.length > 0) {
        data = data[0].subMenu;
        state.erpMenuList = data;
      } else {
        state.erpMenuList = [];
      }
    },
    // 设置权限
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
      // 本地存储下权限数据，防止刷新时候无法显示
      localStorage.setItem('saasPermissions', JSON.stringify(permissions));
    },
    // 获取用户token信息
    SET_TOKEN_INFO(state, data) {
      const { access_token, refresh_token, loginName, companyName = '两港贸易' } = data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('loginName', loginName); // eslint-disable-next-line camelcase
      localStorage.setItem('companyName', companyName); // eslint-disable-next-line camelcase
      state.access_token = access_token;
      // eslint-disable-next-line camelcase
      state.refresh_token = refresh_token; // vuex内保留token信息
      state.loginName = loginName;
      state.companyName = companyName;
    },
    // 获取用户登录信息
    SET_USER_INFO(state, data) {
      const { loginAuthDto, menuList, authCodeSet } = data;
      localStorage.setItem('loginAuthDto', loginAuthDto ? JSON.stringify(loginAuthDto) : ''); //
      localStorage.setItem('userId', loginAuthDto.userId);
      localStorage.setItem('loginName', loginAuthDto.loginName);
      localStorage.setItem('userName', loginAuthDto.userName);
      localStorage.setItem('headImage', loginAuthDto.headImg);
      state.userId = loginAuthDto.userId;
      state.userName = loginAuthDto.userName;
      state.headImage = loginAuthDto.headImg;
      state.loginAuthDto = loginAuthDto;
      state.authCodeSet = authCodeSet; // 保存菜单信息
      state.menuList = menuList;
    },
    CLEAR_USER_INFO(state) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('loginName');
      localStorage.removeItem('companyName');
      localStorage.removeItem('headImage');
      state.access_token = null;
      state.refresh_token = null;
      state.loginName = null;
      state.headImage = '';
      state.companyName = null;
      state.authCodeSet = null;
      state.loginAuthDto = null; // 用户信息
      state.menuList = null; // 菜单信息
    },
  },
  actions: {
    async getTaskCount({ commit }) {
      const res = await httpGet('malicrm/erp/task/getTaskCount');
      if (res.code === 200) {
        commit('SET_TASK_COUNT', res.result);
        return true;
      } else {
        return false;
      }
    },
    async changeMenu({ commit }, type) {
      commit('CHANGE_MENU', type);
      return true;
    },
    async setUserInfo({ commit, dispatch }, data: ErpUseInfo) {
      // 判断没有任何菜单就不进去，给个提示
      if (data.menuList && data.menuList.length > 0) {
        commit('SET_USER_INFO', data);
        commit('SET_CRM_MENU_LIST', data.menuList); // 设置crm菜单
        commit('SET_ERP_MENU_LIST', data.menuList); // 设置ERP菜单
        commit('SET_PERMISSIONS', data.authCodeSet);
        await dispatch('setDictOptions');
        return true;
      } else {
        errorMessage('用户没有任何菜单，请联系管理员');
        return false;
      }
    },
    async clearUserInfo({ commit }, data) {
      commit('CLEAR_USER_INFO', data);
      return true;
    },
    async setTokenInfo({ commit }, data) {
      commit('SET_TOKEN_INFO', data);
      return true;
    },
  },
};
export default userModule;
