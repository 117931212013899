import queryDeclareDetail from './declareDetails';
const MockData: any = {
  '/malicrm/declare/queryDeclareListWithPage': {
    endRow: 0,
    hasNextPage: true,
    hasPreviousPage: true,
    isFirstPage: true,
    isLastPage: true,
    list: [
      {
        arrivalPort: '天津',
        category: 0,
        categoryDesc: '牛',
        clientManager: '客户经理',
        containerCount: 0,
        containerTotalValue: 0,
        containerTotalValueDesc: '245444',
        country: 0,
        countryDesc: '阿根廷',
        createdTime: '2020-12-03',
        creatorName: '创建人',
        customerName: '客户名称',
        declareNo: '0014444447',
        declareSuccessTime: '2020-12-03',
        id: 0,
        isNeedCg: true,
        isNeedJr: true,
        isNeedQg: true,
        nameCn: '牛产品',
        plantNo: 'SIF117',
        purchase: '关联人',
        shortName: '抬头',
        status: 0,
        statusDesc: '待确认',
        supplierName: '供应商',
        tradeCurrency: 0,
        tradeCurrencyDesc: '美元',
        type: '',
      },
    ],
    navigateFirstPage: 0,
    navigateLastPage: 0,
    navigatePages: 0,
    navigatepageNums: [],
    nextPage: 0,
    pageNum: 0,
    pageSize: 0,
    pages: 0,
    prePage: 0,
    size: 0,
    startRow: 0,
    total: 0,
  },
  '/malicrm/declare/queryDeclareDetail/1128245904877352960': queryDeclareDetail,
};

export function mock(url: string) {
  return {
    code: 200,
    message: '',
    result: MockData[url],
  };
}
