
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CrmFormLayout',
  props: {
    title: {
      type: String,
    },
    pageShow: {
      type: Number,
    },
    isCustomClose: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
    },
  },
  emits: ['close', 'pageShowClose'],
  setup(props, ctx) {
    const close = () => {
      if (props.isCustomClose) {
        ctx.emit('pageShowClose', props.pageShow);
      } else {
        ctx.emit('close', false);
      }
    };
    return { close };
  },
});
