import { reactive, ref, toRefs } from 'vue';
export default () => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    customerName: string;
  };
  const formData = reactive({
    amount: '',
    contactId: [],
    customerId: '',
    id: '',
    name: '',
    operateType: 1,
    principalId: [],
    principal: '',
    relevantId: [],
    relevant: '',
    remark: '',
    status: '',
    source: '',
    customerName: '',
    version: null,
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const customFormData = reactive({
    customerOptions: [],
    contactList: [],
  });
  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入商机名称',
        trigger: 'blur',
      },
      {
        max: 30,
        message: '最大长度为30',
        trigger: 'blur',
      },
    ],
    principalId: [
      {
        required: true,
        message: '请选择负责人',
        trigger: 'blur',
      },
    ],
    customerId: [
      {
        required: true,
        message: '请选择客户',
        trigger: 'change',
      },
    ],
    contactId: [
      {
        required: true,
        message: '请选择联系人',
        trigger: 'change',
      },
    ],
    remark: [
      {
        max: 1000,
        message: '最大不能超过1000',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
