import { reactive, ref } from 'vue';
type FormData = {
  addressCity: string;
  addressDetail: string;
  addressDistrict: string;
  addressProvince: string;
  areaId: number;
  cityId: number;
  provinceId: number;
  contactsEmail: string;
  contactsName: string;
  contactsPosition: string;
  contactsSex: 1 | 2;
  contactsTelephone: string;
  contactsWeixin: string;
  customerName: string;
  developUserIdCcly: string;
  developUserIdQhdc: string;
  developUserIdXhdc: string;
  developUserIdXhzy: string;
  id: string | null; // 线索id(添加时不需传值
  isInPool: 0 | 1; // 否放入线索池(0.否 1.是)
  operateType: 1 | 2; // 操作类型 1.添加 2.编辑
  principalId: string[]; // 负责人
  principalIdName: string;
  remark: string; // 备注
  source: number; // 来源
  status: number; // 状态
  [propName: string]: any;
};
export default (params) => {
  const checkPhone = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('电话号码不能为空'));
    }
    setTimeout(() => {
      if (/[\u4E00-\u9FA5]/g.test(value)) {
        callback(new Error('电话号码格式不正确'));
      } else {
        callback();
      }
    }, 100);
  };
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    addressCity: null, // 联系地址
    addressDetail: null, // 联系地址-详细
    addressDistrict: null, // 联系地址-区
    addressProvince: null, // 联系地址-省份
    areaId: null, // 联系地址-区id
    cityId: null, // 联系地址-城市id
    provinceId: null, // 联系地址-省份id
    contactsEmail: null, // 联系人邮箱
    contactsName: null, // 联系人姓名
    contactsPosition: null, // 联系人职位
    contactsSex: 1, //  联系人性别(1.先生 2.女士)
    contactsTelephone: '', // 联系人电话
    contactsWeixin: null, // 联系人微信号
    customerName: null, // 客户名称
    developUserIdCcly: null, // 拓客人用户ID-仓储冷运
    developUserIdQhdc: null, // 拓客人用户ID-期货代采
    developUserIdXhdc: null, // 拓客人用户ID-现货代采
    developUserIdXhzy: null, // 拓客人用户ID-现货质押
    id: null, // 线索id(添加时不需传值)
    isInPool: 0, // 否放入线索池(0.否 1.是)
    operateType: 1, // 操作类型 1.添加 2.编辑
    principalId: null, // 负责人
    principalIdName: null,
    remark: null, // 备注
    source: 1, // 来源
    status: 1, // 状态
    ...params,
  });
  const rules = reactive({
    source: [
      {
        required: true,
        message: '请选择来源',
        trigger: 'change',
      },
    ],
    customerName: [
      {
        required: true,
        message: '请输入客户名称',
        trigger: 'blur',
      },
      {
        max: 100,
        message: '最大长度为100',
        trigger: 'blur',
      },
    ],
    contactsName: [
      {
        required: true,
        message: '请输入联系人',
        trigger: 'blur',
      },
      {
        max: 10,
        message: '最大长度为10',
        trigger: 'blur',
      },
    ],
    contactsPosition: [
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    contactsTelephone: [
      {
        required: true,
        message: '请输入联系电话',
        trigger: 'blur',
      },
      {
        max: 50,
        message: '最大长度为50',
        trigger: 'blur',
      },
      {
        validator: checkPhone,
        trigger: 'blur',
      },
    ],
    contactsEmail: [
      {
        max: 30,
        message: '最大长度为30',
        trigger: 'blur',
      },
    ],
    contactsWeixin: [
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    addressDetail: [
      {
        max: 50,
        message: '最大长度为50',
        trigger: 'blur',
      },
    ],
    contactsSex: [
      {
        required: true,
        message: '请选择性别',
        trigger: 'change',
      },
    ],
    remark: [
      {
        max: 1000,
        message: '长度限制为1000',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};
