
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import useVisible from '@/hooks/useVisible';
import useDeptTree from './useDeptTree';
import { errorMessage } from '@/utils/publicMethods';
import { DeptTree } from '@/types/type';
import { useStore } from '@/store';
// import useMove from '@/hooks/useMove';
export default defineComponent({
  name: 'ChooseMan',
  props: {
    modelValue: {
      type: [Array, String],
    },
    name: {
      type: String as PropType<string>,
    },
    limt: {
      type: Number as PropType<number>,
      default: 1,
    },
    titleLabel: {
      type: String as PropType<string>,
      default: '请选择负责人',
    },
    customOptionLabel: {
      type: String as PropType<string>,
      default: '',
    },
    title: {
      type: String as PropType<string>,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultCreated: {
      type: Boolean,
      default: false,
    },
    // 是否有选择限制
    selectAll: {
      type: Boolean,
      default: true,
    },
    /** 默认不许选择的项 */
    disabledKeys: {
      type: [String, Array],
    },
    placeholder: {
      type: [String],
    },
    isHidden: {
      type: [Boolean],
      default: false,
    },
    /** 判断返回值字符串还是数组 */
    isString: {
      type: [Boolean],
      default: false,
    },
    required: {
      type: [Boolean],
      default: true,
    },
  },
  emits: ['update:modelValue', 'update:name', 'close'],
  setup(props, ctx) {
    const store = useStore();
    const currentUserId = [store.state.user?.userId]; // 获取当前用户id
    const nodeIds = ref(); // 传入的id值
    // 判断传入的类型是否有影响
    if (typeof props.modelValue === 'string') {
      nodeIds.value = props.defaultCreated ? currentUserId : [props.modelValue];
    } else {
      nodeIds.value = props.defaultCreated ? currentUserId : props.modelValue;
    }

    const nodeNames = ref(''); // 显示名字用
    nodeNames.value = props.name && props.name !== '' ? props.name : '';
    const treeRef = ref();
    const dialogRef = ref();
    const { visible, showVisible, closeVisible } = useVisible();
    const cancel = (): void => {
      closeVisible();
    };
    const { treeData, defaultProps, getNodeNames, initTreeData, setTreeDataDisabled } = useDeptTree(props.selectAll);
    /** 提交一次保存数据 */
    const emitData = (ids: (string | number | null | undefined)[], names: string) => {
      if (typeof props.modelValue === 'string') {
        ctx.emit('update:modelValue', ids.toString());
      } else {
        if (props.isString) {
          ctx.emit('update:modelValue', ids.toString());
        } else {
          ctx.emit('update:modelValue', typeof props.modelValue === 'string' ? [ids] : ids);
        }
      }
      ctx.emit('close');
      ctx.emit('update:name', names);
      closeVisible();
    };
    const saveDeptData = () => {
      const treeData = treeRef.value.getCheckedNodes();
      const filterData: DeptTree[] = treeData.filter((v) => v.menuCode === 'user'); // 只获取属性是员工的属性
      const len = filterData.length;
      if (len === 0) {
        if (props.required) {
          let errTitle = '请选择负责人!';
          if (props.title && props.title !== '') {
            errTitle = '请选择' + props.title + '!';
          }
          if (props.placeholder && props.placeholder !== '') {
            errTitle = props.placeholder + '!';
          }
          errorMessage(errTitle);
        } else {
          const ids = [];
          const names = '';
          nodeNames.value = '';
          emitData(ids, names);
        }
      } else {
        if (len > props.limt) {
          errorMessage(`最多可选择${props.limt}项`);
        } else {
          const ids = filterData.map((v) => v.id);
          const names = getNodeNames(ids as string[]);
          nodeNames.value = names;
          emitData(ids, names);
        }
      }
    };
    // 提交空数据
    const clear = () => {
      nodeIds.value = [];
      nodeNames.value = '';
      emitData([], '');
    };
    const openDialog = () => {
      // 暂时先不加拖拽功能
      // nextTick(() => {
      //   let el = document.querySelector('.chooseManDialog ');
      //   useMove(el);
      // });
    };

    initTreeData().then(() => {
      nodeNames.value = getNodeNames(nodeIds.value as string[]);
      // 如果默认有字符显示的名字，则取默认的字符
      if (props.name && props.name !== '') {
        nodeNames.value = props.name;
      }

      // 如果有默认选中行触发一次绑定
      if (props.defaultCreated === true) {
        emitData(currentUserId, nodeNames.value);
      }
      if (props.disabledKeys) {
        treeData.value = setTreeDataDisabled(props.disabledKeys, treeData.value) as DeptTree[];
      }
      // 如果有传入指定部门则只渲染过滤指定部门的值
      if (props.customOptionLabel && props.customOptionLabel !== '') {
        const customOption = (treeData.value[0] as any).subMenu.filter((item) => {
          return item.menuName === props.customOptionLabel;
        });
        if (customOption[0].subMenu && customOption[0].subMenu.length > 0) {
          treeData.value = JSON.parse(JSON.stringify(customOption));
        }
      }
    });
    const disabledKeys = computed(() => {
      return props.disabledKeys;
    });
    const modelValue = computed(() => {
      return props.modelValue;
    });
    /** 监听禁止选中数据，处理禁用选项 */
    watch(modelValue, (newVal) => {
      if (typeof newVal === 'string') {
        nodeIds.value = [props.modelValue];
      } else {
        nodeIds.value = props.modelValue;
      }
      nodeNames.value = getNodeNames(nodeIds.value as string[]);
    });
    watch(
      disabledKeys,
      (newVal) => {
        treeData.value = setTreeDataDisabled(newVal, treeData.value) as DeptTree[];
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      dialogRef,
      treeRef,
      nodeIds,
      visible,
      showVisible,
      closeVisible,
      openDialog,
      treeData,
      defaultProps,
      nodeNames,
      saveDeptData,
      clear,
      cancel,
    };
  },
});
