import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
    clearable: _ctx.isClearable,
    onChange: _ctx.getModelValue,
    class: "full-input tag-scroll",
    ref: "dictSelectRef",
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: index,
          label: item[_ctx.keyLabelOpt],
          value: item[_ctx.keyValueOpt]
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "clearable", "onChange", "disabled"]))
}