import CrmFormLayout from './CrmFormLayout/index.vue';
import DictSelect from './DictSelect/DictSelect.vue';
import ChooseMan from './ChooseMan/ChooseMan.vue';
import CitySelect from './CitySelect/CitySelect.vue';
import ErpDrawer from './ErpDrawer/ErpDrawer.vue';
import ErpView from './ErpView/ErpView.vue';
import TableFileContent from './TableFileContent/TableFileContent.vue';
const components = [
  CrmFormLayout,
  DictSelect,
  ChooseMan,
  CitySelect,
  ErpDrawer,
  ErpView,
  TableFileContent,
];

export default {
  install: (app) => {
    components.forEach((component) => {
      app.component(component.name, component);
    });
  },
};
