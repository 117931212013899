
import { useStore } from '@/store';
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
interface MenuData {
  load: boolean;
  routerInfo: string[] | undefined;
  activePath: string;
  isCollapse: boolean;
  collapseClass: string;
  hoverWidth: string;
}
const taskItem = {
  disabled: null,
  hasMenu: false,
  icon: ' icon-taskList',
  id: '899078550328444928',
  menuCode: 'erpTask',
  menuName: '任务',
  number: 1,
  parentMenu: null,
  pid: '703356062744448000',
  remark: '',
  subMenu: [],
  url: '/erp/taskList',
  yn: null,
};
export default defineComponent({
  components: {},
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const data = reactive<Partial<MenuData>>({
      load: true,
      routerInfo: [],
      activePath: '',
      collapseClass: '',
      isCollapse: false,
      hoverWidth: '240px',
    });
    // 当前所在菜单
    const currentMenu = computed(() => {
      return store.state.user?.currentMenu;
    });
    // 当前选中菜单列表
    const currentMenuList = computed(() => {
      return store.state.user?.currentMenuList;
    });
    const activeIndexs = computed(() => {
      return store.state.browser?.activeIndexs;
    });
    // 跳转连接
    const linkRouter = (item) => {
      console.log(item);
    };
    const getRouterInfo = (menuList: string[]) => {
      data.routerInfo = [];
      setTimeout(() => {
        data.routerInfo = menuList;
      }, 10);
    };
    /** 获取到父级id */
    let pid = 0;
    const getPid = (data, path) => {
      data.forEach((v) => {
        if (v.url === path) {
          pid = v.pid;
        } else {
          if (v.subMenu && v.subMenu.length > 0) {
            getPid(v.subMenu, path);
          }
        }
      });
      return pid;
    };
    const getMenuIndex = (data, pid) => {
      return data.findIndex((v) => v.id === pid);
    };
    // 设置导航栏激活样式
    const setActiveIndex = () => {
      const routerInfo = data.routerInfo;
      if (routerInfo && routerInfo.length > 0) {
        // 还是每次都去读取对应的顺序，因为菜单顺序是动态调整的所以根据自己本身来判断
        const index = getMenuIndex(data.routerInfo, getPid(data.routerInfo, route.fullPath));
        data.activePath = route.fullPath;
        store.commit('setActiveIndexs', index);

        // if (route.meta && route.meta.activePath) {
        //   data.activePath = route.meta.activePath as string;
        //   store.commit('setActiveIndexs', route.meta.activeIndex ? route.meta.activeIndex : -1);
        //   console.log(111);
        // } else {
        //   const index = getMenuIndex(data.routerInfo, getPid(data.routerInfo, route.fullPath));
        //   data.activePath = route.fullPath;
        //   store.commit('setActiveIndexs', index);
        //   console.log(2222);
        // }
      }

      return true;
      // todolist
    };

    const defaultActive = () => {
      const path = route.fullPath;
      if (path.indexOf('/erp') > -1) {
        // 路由是erp的则切换当前展示
        store.dispatch('changeMenu', 'ERP').then(() => {
          // 等切换完成后再开始做设置
          // setActiveIndex();
        });
      } else {
        store.dispatch('changeMenu', 'CRM').then(() => {
          // setActiveIndex();
        });
      }
    };

    // 二级菜单展开
    const showSubMenu = (index) => {
      const indexOf = (activeIndexs.value as number[]).indexOf(index);
      if (indexOf > -1) {
        store.commit('delActiveIndexs', indexOf);
      } else {
        store.commit('setActiveIndexs', index);
      }
    };

    // 二级菜单点击
    const selectedItem = (child) => {
      router.push(child.url);
      data.activePath = child.url;
    };

    const iconClass = (icon) => {
      // 动态切换erp和CRM菜单样式
      if (currentMenu.value === 'CRM') {
        return 'icon-bg_crm ' + icon;
      } else {
        return 'icon-bg ' + icon;
      }
    };

    const hoverCollapse = () => {
      if (data.isCollapse) {
        data.collapseClass = 'hover-flod';
        data.hoverWidth = '240px';
      } else {
        return false;
      }
    };
    const leaveCollapse = () => {
      if (data.isCollapse) {
        data.hoverWidth = '66px';
        data.collapseClass = 'unflod';
      } else {
        return false;
      }
    };
    const collapseSide = () => {
      data.isCollapse = !data.isCollapse;
      if (data.isCollapse) {
        store.dispatch('setLeftMenuWidth', '66px');
        data.hoverWidth = '66px';

        data.collapseClass = 'unflod';
      } else {
        store.dispatch('setLeftMenuWidth', '240px');
        data.hoverWidth = '240px';
        data.collapseClass = '';
      }
    };
    watch(
      () => data.routerInfo,
      () => {
        setActiveIndex();
      }
    );
    watch(
      currentMenuList,
      (newVal) => {
        // 注入任务栏
        const menuList: any[] = JSON.parse(JSON.stringify(newVal));
        if (store.state.user?.currentMenu === 'ERP') {
          menuList.unshift(taskItem);
        }
        getRouterInfo(menuList);
      },
      { deep: true, immediate: true }
    );
    /** 监听菜单数据加载完成 */
    watch(
      () => store.state.user?.menuList,
      () => {
        defaultActive();
      },
      { immediate: true }
    );
    // 路由监听默认路由变化
    watch(
      () => route.path,
      () => {
        setActiveIndex();
      },
      { immediate: true }
    );
    return {
      ...toRefs(data),
      iconClass,
      selectedItem,
      showSubMenu,
      leaveCollapse,
      collapseSide,
      hoverCollapse,
      activeIndexs,
      linkRouter,
    };
  },
});
