import { RootStoreState, TableState } from '@/types/store';

import { Module } from 'vuex';
type TableStateData = {
  id: string;
  data: {
    paginationParams: any;
    queryForm: any;
  };
};
const dictModule: Module<Partial<TableState>, RootStoreState> = {
  state: {
    cache: {},
    currentRows: {},
  },
  getters: {},
  mutations: {
    SET_CURRENT_ROW(state, propData: any) {
      const { id, data } = propData;
      state.currentRows[id] = data;
    },
    SET_CACHE(state, propData: TableStateData) {
      const { id, data } = propData;
      state.cache[id] = data;
    },
    CLEAR_CACHE(state) {
      state.cache = {};
      state.currentRows = {};
    },
  },
  actions: {
    // 清除保存的缓存列表信息
    async clearCache({ commit }) {
      commit('CLEAR_CACHE');
      return true;
    },
    async setCurrentRow({ commit }, propData: any) {
      commit('SET_CURRENT_ROW', propData);
      return true;
    },
    /** 获取缓存数据 */
    async getCurrentRow({ state }, id: string) {
      return state.currentRows[id];
    },
    async setQueryParamsCache({ commit }, propData: TableStateData) {
      commit('SET_CACHE', propData);
      return true;
    },
    /** 获取缓存数据 */
    async getQueryParamsCache({ state }, id: string) {
      return state.cache[id];
    },
  },
};
export default dictModule;
