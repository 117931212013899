import router from '@/router';
import { store } from '@/store';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import request from './request';
import response from './response';
import NProgress from 'nprogress';
import { mock } from '@/mock/mock';
// 这里开发环境给固定字符串，避免改地址热重启
let baseUrl: string | undefined = 'https://erpgateway.mujidigital.com'; // http://172.31.10.200:8080 //https://erpgateway.mujidigital.com //https://gateway.malicn.com
if (process.env.NODE_ENV === 'development') {
  baseUrl = baseUrl || process.env.VUE_APP_API_ROOT;
} else {
  baseUrl = process.env.VUE_APP_API_ROOT;
}
const service = axios.create({
  baseURL: baseUrl,
  timeout: 600000,
});
request(service);
response(service);

interface ResponseData {
  code?: number;
  message?: any;
  result?: any;
  [propName: string]: any;
}
export async function refreshToken(): Promise<any> {
  const refreshToken = localStorage.getItem('refresh_token');
  const accessToken = localStorage.getItem('access_token');
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: process.env.VUE_APP_API_ROOT + '/uac/auth/user/refreshToken',
    auth: {
      username: 'mallee-saas-uac',
      password: 'maliSoaClientSecret',
    },
    params: {
      refreshToken: refreshToken,
      accessToken: accessToken,
    },
  };

  try {
    // 刷新接口可能也会有失败的情况
    const res = await axios(params);
    if (res.data.code === 200) {
      return await store.dispatch('setTokenInfo', res.data.result);
    } else {
      await store.dispatch('clearUserInfo');
      router.push({
        path: '/login',
      });
      return false;
    }
  } catch (error) {
    await store.dispatch('clearUserInfo');
    router.push({
      path: '/login',
    });
    console.log(error);
  }
}

export async function httpGet(url: string, data?: any, isMock = false): Promise<ResponseData> {
  let res: any = null;
  NProgress.start();
  if (isMock === false) {
    res = await service.get(url, data);
  } else {
    // mock状态下走自定义数据结构
    res = await mock(url);
  }
  NProgress.done();
  return res;
}

/** http post方法 */
/**
 * 描述
 * @date 2021-02-04
 * @param {any} url:string
 * @param {any} data?:any
 * @param {any} isMock?:是否是mock模式
 * @returns {any}
 */
export async function httpPost(url: string, data?: any, isMock = false): Promise<ResponseData> {
  NProgress.start();
  let res: AxiosResponse<any> | ResponseData | PromiseLike<ResponseData>;
  if (isMock === false) {
    res = await service.post(url, data);
  } else {
    res = mock(url);
  }

  NProgress.done();
  return res;
}
export async function ajax(params: AxiosRequestConfig): Promise<ResponseData> {
  NProgress.start();
  const res = await service(params);
  NProgress.done();
  return res;
}
