<template>
  <div>
    <slot>
      <div v-if="!readOnly">
        <el-button size="mini" class="table-upload" type="primary" plain @click="showUpload" v-if="!isData" :disabled="disabledProp">上传</el-button>
        <span v-if="isData" class="record__link" style="margin-right: 8px" @click="showView('edit')">查看</span>
        <span v-if="isData && !readOnly" class="record__link" @click="editUpload" :class="disabledProp ? 'disabled-label' : ''">编辑</span>
      </div>
      <div v-else>
        <span v-if="fileArrays.length > 0" class="record__link" :class="{ 'list-left': customClass === 'list-left' }" @click="showView('view')">查看</span>
        <span v-else :class="{ 'list-left': customClass === 'list-left' }">-</span>
      </div>
    </slot>
    <el-dialog
      v-model="uploadVisable"
      :title="fromType < 3 ? '上传文件' : '查看文件'"
      @close="closeDialog"
      :width="width"
      custom-class="upload-dialog__body"
      :append-to-body="true"
    >
      <SingleFileContent
        :customClass="customClass"
        :max="maxImg"
        :fromType="fromType"
        :typeImg="5"
        :fileList="fileArrays"
        @success="getFileArrays"
        v-if="load"
        :tips="tips"
        :showTips="false"
        :fileType="fileType"
        :size="size"
      >
      </SingleFileContent>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button type="primary" @click="setUploadFile" v-if="fromType < 3" :disabled="disabled" size="small">上传</el-button>
          <el-button @click="closeDialog" size="small">关闭</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog :title="imgDialogTitle" v-model="imgVisable" width="680px" custom-class="p-30" @close="imgVisable = false" :append-to-body="true">
      <div class="min-img-warp" v-if="fileArrays && fileArrays.length > 0">
        <el-image style="width: 400px; height: 400px" fit="contain" :src="fileArrays[0].url" :preview-src-list="fileArrays.map((v) => v.url)"> </el-image>
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button @click="imgVisable = false" size="medium">{{ imgBtnTxt }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { getSuffix, showFiles } from '@/utils/publicMethods';
export default {
  name: 'TableFileContent',
  components: { SingleFileContent },
  props: {
    size: {
      type: [Number],
    },
    tips: {
      type: [String],
    },
    fileType: {
      type: [String],
    },
    maxImg: {
      type: [String, Number],
      default: 5,
    },
    width: {
      type: [String],
      default: '1020px',
    },
    readOnly: {
      // 只读
      type: [Boolean],
      default: false,
    },
    rowIndex: {
      type: [String, Number],
      default: 0,
    },
    fileUrlsArray: {
      type: [Array, Object],
      default: function () {
        return [];
      },
    },
    keyId: {
      // 重命名展示变量
      type: [String, Number],
      default: 'fmFileId',
    },
    keyName: {
      type: [String, Number],
      default: 'fmFileName',
    },
    keyUrl: {
      type: [String, Number],
      default: 'fmFileUrl',
    },
    setKeyId: {
      // 重命名提交变量
      type: [String, Number],
      default: 'fmFileId',
    },
    setKeyName: {
      type: [String, Number],
      default: 'fmFileName',
    },
    setKeyUrl: {
      type: [String, Number],
      default: 'fmFileUrl',
    },
    disabledProp: {
      // 判断编辑是否禁用该操作
      type: [Boolean],
      default: false,
    },
    customClass: {
      type: [String],
      default: '',
    },
    fromWhere: {
      type: [String],
      default: '',
    },
  },
  computed: {
    isData() {
      return this.fileArrays && this.fileArrays.length > 0;
    },
    imgDialogTitle() {
      let res = '查看图片';
      if (this.fromWhere === 'express-status') {
        res = '查看快递信息';
      }
      return res;
    },
    imgBtnTxt() {
      let res = '关闭';
      if (this.fromWhere === 'express-status') {
        res = '取消';
      }
      return res;
    },
  },
  data() {
    return {
      imgVisable: false,
      uploadTitle: '上传文件',
      uploadVisable: false,
      disabled: true,
      fromType: 1,
      load: true,
      fileArrays: [], // 查看用对象
      emitFileArrays: [], // 提交出去的对象
      showFilesArrays: [],
      images: [],
    };
  },
  watch: {
    fileUrlsArray: {
      handler() {
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isImage(name) {
      // 显示文件名用
      const arr = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
      const suffix = getSuffix(name);
      return arr.includes(suffix);
    },
    initData() {
      this.fileArrays = [];
      const fileUrlsArray = JSON.parse(JSON.stringify(this.fileUrlsArray));
      if (fileUrlsArray && fileUrlsArray.length > 0) {
        fileUrlsArray.forEach((v) => {
          const objData = {
            name: v[this.keyName],
            url: v[this.keyUrl],
          };
          this.fileArrays.push(objData);
          this.emitFileArrays = fileUrlsArray;
          this.images = this.fileArrays.map((v) => v.url);
        });
      } else {
        this.fileArrays = [];
        this.emitFileArrays = [];
      }
    },
    showView() {
      this.load = false;
      if (this.fileArrays.length > 1) {
        // 如果文件数量大于1 则显示弹窗
        this.fromType = 3;
        this.load = true;
        this.uploadVisable = true;
      } else {
        const name = this.fileArrays[0].name;
        if (this.isImage(name)) {
          this.imgVisable = true;
        } else {
          showFiles(this.fileArrays[0].name, this.fileArrays[0].url);
        }
      }
    },
    closeDialog() {
      setTimeout(() => {
        this.load = false;
      }, 100);

      this.uploadVisable = false;
      this.$emit('close', true);
    },
    showUpload() {
      if (!this.disabledProp) {
        this.load = false;
        setTimeout(() => {
          this.load = true;
          this.uploadVisable = true;
        }, 0);
      }
    },
    editUpload() {
      if (this.disabledProp) {
        return false;
      } else {
        this.load = false;
        setTimeout(() => {
          this.fromType = 2;
          this.load = true;
          this.uploadVisable = true;
        }, 0);
      }
    },
    setUploadFile() {
      const data = {
        data: JSON.parse(JSON.stringify(this.emitFileArrays)),
        index: this.rowIndex,
      };
      this.$emit('getFileUrls', data);

      this.closeDialog();
    },
    getFileArrays(data) {
      // 获取上传数据
      this.emitFileArrays = [];
      if (data.length > 0) {
        data.forEach((v) => {
          const objData = {};
          objData[this.setKeyId] = null;
          objData[this.setKeyName] = v.name;
          objData[this.setKeyUrl] = v.url;
          this.emitFileArrays.push(objData);
          this.disabled = false;
        });
      } else {
        this.disabled = false;
        this.emitFileArrays = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record__link {
  user-select: none;
  color: #2878ff !important;
  &.disabled-label {
    opacity: 0.5;
  }
}
.record__link.list-left {
  text-align: left;
  margin-left: 0;
}
.min-img-warp {
  width: 680px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
</style>
