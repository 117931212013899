
import { CityData } from '@/types/type';
import { computed, defineComponent, nextTick, PropType, reactive, Ref, ref, watch } from 'vue';
import areaDataJson from './city.json';

export default defineComponent({
  name: 'CitySelect',
  props: {
    /** 省市区顺序数组[省id，市id，区id] */
    data: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    level3: {
      // 是否显示第三级
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isMunicipality: {
      // 业务属性是否是直辖
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, ctx) {
    const selectData = reactive({
      province: {},
      city: {},
      county: {},
    });
    const cityRef = ref();
    const countyRef = ref();
    const provinceItems = ref();
    const cityItems = ref();
    const countyItems = ref();
    const emitData: Ref<CityData> = ref({
      province: {
        id: '',
        name: '',
      },
      city: {
        id: '',
        name: '',
      },
      county: {
        id: '',
        name: '',
      },
    });
    const propsData = computed(() => {
      return props.data;
    });
    const level2 = ref(true);
    const changeEmitData = (data) => {
      ctx.emit('change', data);
    };

    const showLevel2 = (id: string) => {
      // 北京 天津 上海 重庆
      let municipalityArr = ['368100109646176256', '368100134023471104', '368100110396956672', '368100175949733888'];

      level2.value = !municipalityArr.includes(id);
    };
    const getProvince = (data) => {
      selectData.city = {};
      selectData.county = {};
      // 兼容下，应该是bug select置空不会更新数据
      if (cityRef.value) {
        cityRef.value.selectedLabel = '';
      }
      if (countyRef.value) {
        countyRef.value.selectedLabel = '';
      }
      emitData.value = {
        province: {
          id: data.id,
          name: data.nodeName,
        },
        city: {
          id: '',
          name: '',
        },
        county: {
          id: '',
          name: '',
        },
      };
      if (data.children) {
        cityItems.value = data.children;
      } else {
        cityItems.value = [];
      }
      countyItems.value = [];

      //如果是直辖市不显示第二级数
      if (props.isMunicipality) {
        showLevel2(data.id);
      }
      changeEmitData(emitData.value);
    };
    const getCity = (data) => {
      emitData.value.city = {
        id: data.id,
        name: data.nodeName,
      };
      // 置空县市
      emitData.value.county = {
        id: '',
        name: '',
      };
      if (data.children) {
        countyItems.value = data.children;
      } else {
        countyItems.value = [];
      }
      changeEmitData(emitData.value);
    };
    const getCounty = (data) => {
      const params = {
        id: data.id,
        name: data.nodeName,
      };
      emitData.value.county = params;

      changeEmitData(emitData.value);
    };
    /** 设置下拉数据来源 */
    const setDataItems = (vals: string[]) => {
      let city;
      let county;
      // 设置省份
      (provinceItems.value as any[]) = areaDataJson.data;
      // 设置市区
      if (vals && vals[0]) {
        city = areaDataJson.data.filter((v) => {
          return v.id === vals[0];
        })[0];

        cityItems.value = city?.children ? city.children : [];
      }
      // 设置县区
      if (vals && vals[1] && city?.children) {
        county = city.children.filter((v) => v.id === vals[1])[0];
        countyItems.value = county?.children ? county.children : [];
      }
      // 默认赋值用回显
      selectData.province = { id: vals && vals[0] ? vals[0] : '' };
      selectData.city = { id: vals && vals[1] ? vals[1] : '' };
      selectData.county = { id: vals && vals[2] ? vals[2] : '' };
      // 判断直辖市第二级
      if (props.isMunicipality) {
        showLevel2((selectData.province as any).id);
      }
      nextTick(() => {
        if (countyRef.value) {
          countyRef.value.focus();
        }
      });
    };
    setDataItems(props.data as string[]);
    watch(propsData, (newVal) => {
      if (newVal && newVal.length > 0) {
        setDataItems(newVal);
      }
    });
    return {
      selectData,
      provinceItems,
      cityItems,
      countyItems,
      getProvince,
      getCity,
      getCounty,
      cityRef,
      countyRef,
      level2,
    };
  },
});
