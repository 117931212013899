import { BrowserState, MenuWidth, RootStoreState } from '@/types/store';
import { Module } from 'vuex';

const browserModule: Module<Partial<BrowserState>, RootStoreState> = {
  state: {
    browserWidth: '',
    browserHeight: '',
    leftMenuWidth: '240px', // 190px
    rightContentWidth: '',
    mainContentHeight: '',
    menuFlag: true, // 左侧菜单 true: 展开状态, false: 收缩
    isCollapse: false,
    breadList: [],
    activeIndexs: [],
  },
  getters: {
    getBrowserWidth: (state) => state.browserWidth,
    getBrowserHeight: (state) => state.browserHeight,
    getLeftMenuWidth: (state) => {
      return state.leftMenuWidth;
    },
    getRightContentWidth: (state) => state.rightContentWidth,
    getMainContentHeight: (state) => state.mainContentHeight,
    getMenuFlag: (state) => state.menuFlag,
  },
  mutations: {
    clearBreadList(state) {
      state.breadList = [];
    },
    delBreadList(state, index) {
      (state.breadList as string[]).splice(index, 1);
    },
    setBreadList(state, status) {
      (state.breadList as string[]).push(status);
    },
    emptyBreadList(state) {
      state.breadList = [];
    },
    clearActiveIndexs(state) {
      state.activeIndexs = [];
    },
    delActiveIndexs(state, index) {
      (state.activeIndexs as number[]).splice(index, 1);
    },
    setActiveIndexs(state, status) {
      (state.activeIndexs as number[]).push(status);
      state.activeIndexs = Array.from(new Set(state.activeIndexs));
    },

    setCollapse(state, status) {
      state.isCollapse = status;
    },
    setBrowserWidth(state, browserWidth) {
      state.browserWidth = browserWidth;
      // 更新右边内容区的宽度 去掉px
      browserWidth = browserWidth.replace(/px/g, '');
      const leftMenuWidth = (state.leftMenuWidth as MenuWidth).replace(
        /px/g,
        ''
      );
      state.rightContentWidth = browserWidth - Number(leftMenuWidth) + 'px';
    },
    setBrowserHeight(state, browserHeight) {
      state.browserHeight = browserHeight;
      // 更新主内容区的高度
      browserHeight = browserHeight.replace(/px/g, '');
      state.mainContentHeight = browserHeight - 70 + 'px';
    },
    setLeftMenuWidth(state, leftMenuWidth) {
      state.leftMenuWidth = leftMenuWidth;

      // 更新右边内容区的宽度
      const browserWidth = (state.browserWidth as MenuWidth).replace(/px/g, '');
      leftMenuWidth = leftMenuWidth.replace(/px/g, '');
      state.rightContentWidth = Number(browserWidth) - leftMenuWidth + 'px';
    },
    setMenuOpen(state) {
      state.menuFlag = true;
    },
    setMenuClose(state) {
      state.menuFlag = false;
    },
  },
  actions: {
    setCollapse({ commit }, status) {
      commit('setCollapse', status);
    },
    setBrowserWidth({ commit }, browserWidth) {
      commit('setBrowserWidth', browserWidth);
    },
    setBrowserHeight({ commit }, browserHeight) {
      commit('setBrowserHeight', browserHeight);
    },
    setLeftMenuWidth({ commit }, leftMenuWidth) {
      commit('setLeftMenuWidth', leftMenuWidth);
    },
    setMenuOpen({ commit }) {
      commit('setLeftMenuWidth', '190px');
      commit('setMenuOpen');
    },
    setMenuClose({ commit }) {
      commit('setLeftMenuWidth', '50px');
      commit('setMenuClose');
    },
  },
};

export default browserModule;
