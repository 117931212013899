import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/layout/LayoutContent.vue';
// import { autoRouter } from './autoRouter';
import erp from './erp';
import crm from './crm';
import system from './system';
// const autoRoutes = autoRouter(['./crm', './erp']);
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/clue/clueList',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
  },
  // ...autoRoutes,
  {
    path: '/layout',
    name: 'Layout',
    component: Home,
    children: [
      ...crm,
      // 订单列表
      ...erp,
      ...system,
    ],
  },
  {
    path: '/:w+',
    component: () => import('../views/404/index.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
