
import { httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import CitySelect from '@/components/CitySelect/CitySelect.vue';
import router from '@/router';
import { useStore } from '@/store';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';

import { computed, defineComponent, ref } from 'vue';
import useFormData from './useFormData';
export default defineComponent({
  components: { CitySelect, ChooseMan },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
    listType: {
      type: [Number],
      default: 1,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const addressData = ref();
    const isInPool = ref(0);
    // 只有管理员可以编辑
    const isAdmin = computed(() => {
      const store = useStore();
      return store.state.user?.userId !== '1';
    });
    let params: any = {};
    if (props.formType === 2) {
      params = Object.assign({ operateType: 2 }, props.data);
      params.isInPool = params.isInPool ? 1 : 0;
      const data = props.data as any;
      // 设置区域
      addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const { rules, formData, formRef } = useFormData(params);
    // 部门组件对象
    const principalRef = ref();
    const changePool = (value) => {
      formData.isInPool = value;
      if (value === 1) {
        principalRef.value.clear();
      }
      formRef.value.clearValidate('principalId');
    };
    // 获取省市区联动
    const getArea = (data) => {
      formData.addressProvince = data.province.name;
      formData.provinceId = data.province.id;
      formData.addressCity = data.city.name;
      formData.cityId = data.city.id;
      formData.addressDistrict = data.county.name;
      formData.areaId = data.county.id;
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const checkRes = await httpPost('/malicrm/common/checkCustomerNameActive', {
        customerName: formData.customerName,
        leadsId: formData.id,
      });
      if (checkRes.code === 200) {
        ajaxLoading.lock();
        const res = await httpPost('/malicrm/leads/operateLeads', formData);
        if (res.code === 200) {
          successMessage('已保存');
          if (formData.operateType === 2) {
            if (props.listType === 1) {
              router.push('/clue/clueList');
            }
            if (props.listType === 2) {
              router.push('/clue/cluepool');
            }
          }
          // 这里的解锁在页面返回解除掉，这样可以保证一定不会重复提交
          ajaxLoading.unLock();
          close(true);
        } else {
          errorMessage(res.message);
          ajaxLoading.unLock();
        }
      } else {
        errorMessage(checkRes.message);
        ajaxLoading.unLock();
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };

    return {
      isAdmin,
      close,
      formData,
      getArea,
      addressData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      isInPool,
      changePool,
      principalRef,
    };
  },
});
