import { DeclareForm } from '@/views/crm/declare/components/types/declareForm';

const data: DeclareForm = {
  arrivalPort: '',
  category: 0,
  categoryDesc: '牛',
  clientManager: '客户经理',
  consigneeType: 0,
  consigneeTypeDesc: '',
  consigneeVo: {
    confirmOperator: '黄强',
    confirmRemark:
      '审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息审核通过信息',
    confirmTime: '2021-12-05',
    shortName: '指定抬头',
  },
  standardContractConfigVos: [
    {
      configMax: 45,
      configMin: 1,
      minUseDays: 0,
      rate: 1,
    },
    {
      configMax: 90,
      configMin: 45,
      minUseDays: 0,
      rate: 2,
    },
    {
      configMax: null,
      configMin: 90,
      minUseDays: 0,
      rate: 3,
    },
  ],
  currentContractConfigVos: [
    {
      configMax: 45,
      configMin: 1,
      minUseDays: 0,
      rate: 3,
    },
    {
      configMax: 90,
      configMin: 2,
      minUseDays: 0,
      rate: 4,
    },
    {
      configMax: 0,
      configMin: 0,
      minUseDays: 0,
      rate: 5,
    },
  ],
  country: 0,
  countryDesc: '巴西',
  createName: '创建人',
  createdTime: '2020-12-15',
  customerId: 0,
  customerName: '客户名称',
  depositRate: 0,
  epicSupplierId: 0,
  epicSupplierName: 'epic供应商',
  id: '',
  incotermType: 0,
  incotermTypeDesc: '',
  isNeedCg: true,
  isNeedHalfService: true,
  isNeedJr: true,
  isNeedQg: true,
  payAging: 0,
  payAgingDesc: '',
  payType: 0,
  payTypeDesc: '',
  plantNo: 'SIF117',
  productVo: {
    containerCount: 0,
    containerPerWeight: 5000,
    containerPerWeightDesc: '34444',
    containerType: 1,
    containerTypeDesc: '大柜',
    containerValue: 0,
    containerValueDesc: '22222',
    productListVos: [
      {
        nameCn: '牛前五件',
        unitPrice: 0,
        unitPriceDesc: '5000',
        weight: 0,
        weightDesc: '500',
      },
    ],
    totalAmount: 0,
    totalAmountDesc: '111111111',
    tradeCurrency: 0,
    tradeCurrencyDesc: '美元',
  },
  purchase: '采购人',
  ratioRate: 0,
  remark: '',
  reviewVo: {
    attachment: [
      {
        attachmentName: '开发计划.xlsx',
        id: '',
        url: 'https://minio.maat.link/crmprod/1128064725196740608/erp1.6.2开发计划(1).xlsx',
      },
      {
        attachmentName: '开发计划.xlsx',
        id: '',
        url: 'https://minio.maat.link/crmprod/1128064725196740608/erp1.6.2开发计划(1).xlsx',
      },
      {
        attachmentName: '开发计划.xlsx',
        id: '',
        url: 'https://minio.maat.link/crmprod/1128064725196740608/erp1.6.2开发计划(1).xlsx',
      },
    ],
    reviewOperator: '复核人',
    reviewRemark: '复核说明复核说明复核说明复核说明',
    reviewStatus: 1,
    reviewStatusDesc: '符合状态',
    reviewTime: '2021-05-21',
  },
  settleType: 0,
  settleTypeDesc: '',
  shippingDate: '',
  supplierId: 0,
  supplierName: '供应商',
  version: 0,
  currentBillingVo: {
    agencyFee: 2,
    agencyFeeUnit: 2,
    agencyFeeUnitDesc: '21',
    clearanceFee: 2,
    clearanceFeeLarge: 2,
    clearanceFeeLargeDesc: '21',
    halfQhdcServiceFee: 2,
    halfQhdcServiceFeeDesc: '21',
    halfQhdcServiceFeeUnit: 2,
    halfQhdcServiceFeeUnitDesc: '21',
  },
  standardBillingVo: {
    agencyFee: 2,
    agencyFeeUnit: 2,
    agencyFeeUnitDesc: '21',
    clearanceFee: 2,
    clearanceFeeLarge: 2,
    clearanceFeeLargeDesc: '21',
    halfQhdcServiceFee: 2,
    halfQhdcServiceFeeDesc: '21',
    halfQhdcServiceFeeUnit: 2,
    halfQhdcServiceFeeUnitDesc: '21',
  },
};
export default data;
