export default [
  {
    path: '/system/frameList', // 组织框架
    name: 'frameList',
    component: () => import('../views/crm/system/frameList/frameList.vue'),
  },
  {
    path: '/system/frame', // 组织框架
    name: 'frame',
    component: () => import('../views/crm/system/frame.vue'),
  },
  {
    path: '/system/permission', // 权限分配
    name: 'permission',
    component: () => import('../views/crm/system/permission.vue'),
  },
  {
    path: '/uas/role/bindMenu', // 权限分配
    name: 'BindMenu',
    component: () => import('../views/uac/role/bind-menu/index.vue'),
  },
  {
    path: '/uas/role/bindAction', // 权限分配
    name: 'BindAction',
    component: () => import('../views/uac/role/bind-action/index.vue'),
  },
  {
    path: '/system/accountList', // 客户账号
    name: 'AccountList',
    component: () => import('../views/crm/system/accountList/accountList.vue'),
  },
  {
    path: '/system/menuManage', // 菜单管理
    name: 'menuManage',
    component: () => import('../views/crm/system/menuManage.vue'),
  },
  {
    path: '/system/permissionList', // 菜单管理
    name: 'permissionList',
    // component: () => import('../views/uac/action/list/index.vue')
    component: () =>
      import('../views/crm/system/permissionList/permissionList.vue'),
  },
  {
    path: '/cache/cacheIndex', // 菜单管理
    name: 'cacheIndex',
    component: () => import('../views/crm/cache/cacheIndex.vue'),
  },
  {
    path: '/cache/freeTypeLabel', // 菜单管理
    name: 'freeTypeLabel',
    component: () => import('../views/crm/cache/freeTypeLabel.vue'),
  },
];
