import { httpPost } from '@/api';
import { ISelectItem } from '@/types/type';
import { transISelectItem } from '@/utils/publicMethods';
import { Ref, ref } from 'vue';

export default function useCountryPlant() {
  /** 国家联系人列表 */
  const countryList = ref([]);
  /** 客户联系人列表 */
  const contactList: Ref<ISelectItem[]> = ref([]);
  const portList: Ref<ISelectItem[]> = ref([]);
  // 获取港口信息
  const setPortList = async () => {
    const res = await httpPost('/malicrm/port/queryByName', {
      portName: '',
      pageSize: 9999,
      pageNum: 1,
    });
    if (res.code === 200) {
      const resultItems = transISelectItem(res.result as string[]);
      portList.value = resultItems;
      return resultItems;
    } else {
      portList.value = [];
      return [];
    }
  };
  const setCountryList = async (category: any) => {
    const res = await httpPost('/malicrm/approval/queryCountry', { category });
    if (res.code === 200) {
      countryList.value = res.result;
      return res.result;
    } else {
      countryList.value = [];
      return [];
    }
  };
  const setContactList = async (customerId: string, isBussiness = true) => {
    const res = await httpPost('/malicrm/customer/queryContact', {
      customerId,
      pageNum: 1,
      pageSize: 9999,
    });
    if (res.code === 200) {
      if (isBussiness) {
        const arrList: ISelectItem[] = [];
        res.result.forEach((v) => {
          arrList.push({
            label: v.name + v.position,
            value: v.id,
          });
        });
        console.log(arrList);
        contactList.value = arrList;
      } else {
        contactList.value = res.result;
      }
    } else {
      contactList.value = [];
      return [];
    }
  };

  return {
    setContactList,
    setCountryList,
    setPortList,
    portList,
    countryList,
    contactList,
  };
}
