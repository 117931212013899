<template>
  <CrmFormLayout title="个人资料">
    <div>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="80px" class="user-content maili-edit__form">
        <el-form-item label="用户头像" prop="headImg" class="headimg-item">
          <div>
            <img :src="formData.headImg" class="headImg" alt="" />
            <HeadImgContent
              class="upload-demo"
              :headers="token"
              :action="uploadImageUrl"
              :fileType="fileType"
              multiple
              :limit="1"
              :fileUrlsArray="fileUrlsArray"
              @change="clearImage"
              @getFileUrls="getFileUrls"
            >
            </HeadImgContent>
          </div>
        </el-form-item>
        <el-form-item label="修改密码" prop="loginPwd">
          <el-input
            size="small"
            maxlength="20"
            type="password"
            show-password
            clearable
            v-model="formData.loginPwd"
            placeholder="只可输入英文+数字，需大于6位小于10位"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select size="small" v-model="formData.sex" filterable placeholder="性别">
            <el-option v-for="item in sexList" :key="item.value" :label="item.value" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input size="small" maxlength="30" clearable v-model="formData.email" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobileNo">
          <el-input size="small" maxlength="11" clearable v-model="formData.mobileNo" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="saveBtn">保存</button>
        <button class="crm-form__button cancel" @click="cancelBtn">取消</button>
      </div>
    </div>
  </CrmFormLayout>
</template>
<script>
import HeadImgContent from './headImgContent.vue';
const checkPwd = (rule, value, callback) => {
  if (value === '') {
    callback();
  } else {
    let reg = /^[A-Za-z0-9]{6,10}$/; // eslint-disable-line
    const val = reg.test(value);
    if (val !== true && value !== null) {
      callback(new Error('只可输入英文+数字，需大于6位小于10位'));
    } else {
      callback();
    }
    callback();
  }
};
const checkMobileNoWithNull = (rule, value, callback) => {
  if (value !== '' && value !== undefined && value !== null) {
    const reg = /^1\d{10}$/;
    const val = reg.test(value);
    if (val !== true) {
      callback(new Error('请输入正确的手机号格式'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
const checkEmail = (rule, value, callback) => {
  if (value === '' || value === undefined) {
    callback();
  } else {
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    const val = reg.test(value);
    if (val !== true && value !== null) {
      callback(new Error('邮箱格式不正确'));
    } else {
      callback();
    }
    callback();
  }
};
export default {
  components: { HeadImgContent },
  data() {
    return {
      fileUrlsArray: [],
      token: { Authorization: 'Bearer ' + this.$store.getters.getAccessToken },
      uploadImageUrl: process.env.VUE_APP_UPLOAD_FILE,
      fileType: '.jpg,.jpeg,.gif,.png,.bmp',
      formData: {},
      loginName: '',
      departmentList: [],
      positionList: [],
      sexList: [
        {
          code: 1,
          value: '男',
        },
        {
          code: 2,
          value: '女',
        },
      ],
      rules: {
        userName: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        loginPwd: [{ validator: checkPwd, trigger: ['blur', 'input'] }],
        mobileNo: [
          {
            required: false,
            validator: checkMobileNoWithNull,
            trigger: 'blur',
          },
        ],
        email: [{ required: false, validator: checkEmail, trigger: 'blur' }],
        departmentId: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        positionId: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.loginName = this.$store.getters.getLoginName;
    this.findUserInfoFn(this.loginName);
  },
  computed: {},
  methods: {
    clearImage() {
      this.fileUrlArray = [];
    },
    doLoginAfter() {
      this.ajax({
        url: '/uac/user/loginAfter/1',
        data: {},
        loading: false,
        success: (res) => {
          if (res.code === 200) {
            this.$store.dispatch('setUserInfo', res.result);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    findUserInfoFn(loginName) {
      this.ajax({
        url: `/uac/user/queryUserInfo/${loginName}`,
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.formData = data;
        },
      });
    },
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.saveUserFn();
        } else {
          return false;
        }
      });
    },
    saveUserFn() {
      this.ajax({
        url: '/uac/crmUser/save',
        data: this.formData || '',
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('操作成功');
            this.$emit('close');
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    saveBtn() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          console.info('this.formData', this.formData);
          this.ajax({
            url: '/uac/crmUser/save',
            data: this.formData || '',
            loading: true,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('操作成功');
                this.doLoginAfter();
                this.$emit('close');
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          return false;
        }
      });
    },
    cancelBtn() {
      this.$emit('close');
    },
    getFileUrls(titleImg, fileNameArray, fileUrlArray) {
      this.formData.headImg = fileUrlArray[0];
      console.info('this.formData', this.formData.headImg);
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.menu-cascader {
  width: 100%;
  .el-cascader__label {
    line-height: 36px;
  }
}

.headImg {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  float: left;
}

.upload-demo {
  float: left;
  margin-left: 20px;
  margin-top: 12px;
}

.el-input,
.el-select {
  width: 420px;
}

.user-content {
  background: #fff;
  border: 1px solid #ebeef5;
  padding: 25px 0 70px 5px;
}
</style>
