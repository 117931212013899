// import { store } from '@/store';
import { httpPost } from '@/api';
import { DeptTree } from '@/types/type';
import { reactive, Ref, ref } from 'vue';

const useDeptTree = (selectAll: boolean) => {
  const treeData: Ref<DeptTree[]> = ref([] as any);
  const defaultProps = reactive({
    children: 'subMenu',
    label: 'menuName',
  });
  // 部门id和名称的map集合
  const treeNameMaps = ref({});
  const nodeNames: Record<string, any> = {};

  // 获取部门数据
  const getTreeData = async () => {
    // 有些选择不能选择所有人员，还是不要缓存了直接每次获取更新最新的数据
    const url = selectAll ? '/uac/department/getDepartmentPersonTreeAll' : '/uac/department/getDepartmentPersonTree';
    const res = await httpPost(url);
    if (res.code === 200) {
      return res.result as DeptTree[];
    } else {
      return [];
    }
  };
  /** 递归获取名字节点细 */
  const setNodeName = (data: DeptTree[]) => {
    data.forEach((v) => {
      if (v.id) {
        nodeNames[v.id] = v.menuName;
      }
      if (v.subMenu && v.subMenu.length > 0) {
        setNodeName(v.subMenu);
      }
    });
    return nodeNames;
  };
  const setTreeDataDisabled = (disabledKeys, data: DeptTree[]) => {
    // 递归处理禁用选项
    if (data && data.length > 0) {
      data.forEach((v) => {
        if (disabledKeys.includes(v.id)) {
          v.disabled = true;
        }
        if (v.subMenu && v.subMenu.length > 0) {
          setTreeDataDisabled(disabledKeys, v.subMenu);
        }
      });
      return Object.assign([], data);
    }
  };
  /** 获取id对应的部门人名字 */
  const getNodeNames = (idKeys: string[]) => {
    const nameArrs: string[] = [];
    if (idKeys && idKeys.length > 0) {
      idKeys.forEach((key) => {
        if (treeNameMaps.value[key] && treeNameMaps.value[key] !== '') {
          nameArrs.push(treeNameMaps.value[key]);
        }
      });
    }
    return nameArrs.join('、');
  };
  const initTreeData = async () => {
    const data = await getTreeData();
    treeData.value = data;
    treeNameMaps.value = setNodeName(data);
    return true;
  };

  return {
    treeData,
    defaultProps,
    treeNameMaps,
    getNodeNames,
    initTreeData,
    setTreeDataDisabled,
  };
};

export default useDeptTree;
