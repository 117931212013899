import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import autoComponents from './components';
import App from './App.vue';
import router from './router/index';
import directives from './directives/index';
import 'xe-utils';
import { store, key } from './store';
import mixin from './mixins/index';
import './assets/css/index.scss';
import 'nprogress/nprogress.css';
import './assets/css/element-variables.scss';
// 缓存清除机制;
const clearCache = () => {
  let version = process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : '';
  let localVersion = localStorage.getItem('saas_version');
  if (localVersion) {
    if (localVersion !== version) {
      localStorage.clear();
      localStorage.setItem('saas_version', version);
      console.log('version' + localVersion);
    }
  } else {
    localStorage.clear();
    localStorage.setItem('saas_version', version);
    console.log('version' + localVersion);
  }
};
clearCache();
createApp(App).mixin(mixin).use(store, key).use(router).use(ElementPlus, { locale }).use(autoComponents).use(directives).mount('#app');
