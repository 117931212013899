import { reactive, ref } from 'vue';

export default (params) => {
  const checkPhone = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('电话号码不能为空'));
    }
    setTimeout(() => {
      if (/[\u4E00-\u9FA5]/g.test(value)) {
        callback(new Error('电话号码格式不正确'));
      } else {
        callback();
      }
    }, 100);
  };
  const formRef = ref(); // 表单校验对象使用
  const formData = reactive({
    addressCity: '', //  联系地址-城市 ,
    addressDetail: '', //  联系地址-详细 ,
    addressDistrict: '', //  联系地址-区 ,
    addressProvince: '', //  联系地址-省份 ,
    affiliatedEnterprise: '',
    annualRevenue: 1, //  年营业额(1.100万以下 2.100-500万 3.500-1000万 4.1000-5000万 5.5000万-1亿 6.1亿以上) ,
    areaId: '', //  联系地址-区id
    cityId: '', //  联系地址-城市id ,
    businessLicenseFileName: '', //  营业执照文件名称  ,
    businessLicenseFileUrl: '', //  营业执照文件路径 ,
    cardDtoList: [], // 法人身份证文件名称 法人身份证文件路径
    openingPermitFileName: '', //  开户许可证名称 ,
    openingPermitFileUrl: '', //  开户许可证路径 ,
    invoiceInfo: '', //  开票信息 ,
    customerName: '', //  客户名称 ,
    customerNo: '', // 客户编号
    developUserIdCcly: '', //  拓客人用户ID-仓储冷运 ,
    developUserIdCclyName: '', //  拓客人用户ID-仓储冷运 ,
    developUserIdQhdc: '', //  拓客人用户ID-期货代采 ,
    developUserIdQhdcName: '', //  拓客人用户ID-期货代采 ,
    developUserIdXhdc: '', //  拓客人用户ID-现货代采 ,
    developUserIdXhdcName: '', //  拓客人用户ID-现货代采 ,
    developUserIdXhzy: '', //  拓客人用户ID-现货质押 ,
    developUserIdXhzyName: '', //  拓客人用户ID-现货质押 ,
    employeeNumber: 1, //  员工数(1.0-20人 2.20-99人 3.100-499人 4.500-999人 5.1000-9999人 6.10000人以上)
    id: '', //  客户id(新增时不需传值) ,
    isInPool: '', //  是否放入客户池(0.否 1.是) ,
    operateType: '', //  1.新增 2.编辑 3.删除 4.分配 5.还原 ,
    ownership: '', //  公司性质(1.国企 2.民企 3.合资 4.外资 5.政府机关 6.事业单位 7.非营利组织) ,
    principalId: [], //   负责人 ,
    provinceId: '', //  联系地址-省份id ,
    rating: 1, //  星级(1.1星 2.2星 3.3星 4.4星 5.5星) ,
    remark: '',
    source: 1, //   来源(1.展会 2.地推 3.网络营销 4.实体广告 5.合作伙伴 6.员工介绍 7.其他) ,
    version: '', //  版本号(还原校验)
    ...params,
  });
  const rules = reactive({
    source: [
      {
        required: true,
        message: '请选择来源',
        trigger: 'change',
      },
    ],
    customerName: [
      {
        required: true,
        message: '请输入客户名称',
        trigger: 'blur',
      },
      {
        max: 100,
        message: '最大长度为100',
        trigger: 'blur',
      },
    ],
    contactsName: [
      {
        required: true,
        message: '请输入联系人',
        trigger: 'blur',
      },
      {
        max: 10,
        message: '最大长度为10',
        trigger: 'blur',
      },
    ],
    contactsPosition: [
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    contactsTelephone: [
      {
        required: true,
        message: '请输入联系电话',
        trigger: 'blur',
      },
      {
        max: 50,
        message: '最大长度为50',
        trigger: 'blur',
      },
      {
        validator: checkPhone,
        trigger: 'blur',
      },
    ],
    contactsEmail: [
      {
        max: 30,
        message: '最大长度为30',
        trigger: 'blur',
      },
    ],
    contactsWeixin: [
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    addressDetail: [
      {
        max: 50,
        message: '最大长度为50',
        trigger: 'blur',
      },
    ],
    contactsSex: [
      {
        required: true,
        message: '请选择性别',
        trigger: 'change',
      },
    ],
    remark: [
      {
        max: 1000,
        message: '长度限制为1000',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};
