
import ErpHeader from './ErpHeader.vue';
import SideBar from './SideBar.vue';
import ErpBread from './ErpBread.vue';
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
  props: {},
  components: {
    ErpHeader,
    SideBar,
    ErpBread,
  },
  setup() {
    const store = useStore();
    const isErp = computed(() => {
      return store.state.user?.currentMenu === 'ERP';
    });
    const leftWidth = computed(() => {
      return store.state.browser?.leftMenuWidth;
    });

    return { isErp, leftWidth };
  },
});
