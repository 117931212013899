import { ref } from 'vue';

export default function useVisible() {
  const visible = ref(false);
  const formType = ref(1);
  const showType = ref(1);
  const showVisible = () => {
    visible.value = true;
  };
  const closeVisible = () => {
    visible.value = false;
  };
  const setFormType = (type: number) => {
    formType.value = type;
  };
  const setShowType = (type: number) => {
    showType.value = type;
  };
  return {
    visible,
    formType,
    showType,
    showVisible,
    closeVisible,
    setFormType,
    setShowType,
  };
}
