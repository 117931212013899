import router from '@/router';
import { AxiosInstance } from 'axios';

const request = (service: AxiosInstance) => {
  service.interceptors.request.use((config) => {
    // 设置token请求头
    const token = localStorage.getItem('access_token');
    if (token && token !== '') {
      config.headers.Authorization = 'Bearer ' + token;
    } else {
      // 如果没有token或者清除了就重登录
      router.push('/login');
    }

    return config;
  });
};
export default request;
