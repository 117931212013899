import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eeb63ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "crm-form__title" }
const _hoisted_2 = { class: "crm-form__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["crm-form__layout", _ctx.customClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: "el-icon-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}