import { computed } from 'vue';
type UploadResponse = {
  code: number;
  data: string;
  msg: string;
};
export default (props) => {
  const accept = computed(() => {
    // 对于特殊需求的限制类型只要在这里加就可以了，不需要再每个页面去传递
    let types = '';
    switch (props.fileType) {
      case 'default':
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.txt,.xls,.xlsx,.zip';
        break;
      case 'noZip':
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.txt,.xls,.xlsx';
        break;
      case 'app':
        types = '.apk,.ipa';
        break;
      // crm 供应商文件类型 基础信息冷库合同
      case 'crmStorageContract':
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.bmp,.zip';
        break;
      case 'supplierFile':
        types = '.pdf,.doc,.docx,.PDF,.DOC,.DOCX';
        break;
      // crm 供应商文件图片类型
      case 'supplierImg':
        types = '.jpg, .png, .gif,.JPG,.PNG,.GIF';
        break;
      case 'basicLabelImg':
        types = '.jpg, .png, .gif,.pdf,.jpeg,.JPG,.PNG,.GIF,.PDF,.JPEG';
        break;
      case 'video':
        types = '.mp4';
        break;
      case 'pdf':
        types = '.pdf';
        break;
      case 'crmAgentContract':
      case 'crmStorageEpidemic': // 冷库防疫说明
        types = '.pdf,.doc,.docx';
        break;
      case 'clueType':
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.bmp,.zip';
        break;
      case 'image':
        types = '.pdf,.jpg,.png,.gif,.bmp';
        break;
      case 'crmOfferStandard':
        types = '.pdf, .doc, .docx, .jpg, .png, .gif, .txt,.xls,.xlsx,.zip,.PDF,.DOC,.DOCX,.JPG,.PNG,.GIF,.TXT,.XLS,.XLSX,.ZIP';
        break;
      case 'outStore':
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.xls,.xlsx,.csv,.bmp';
        break;
      case 'statement':
        types = '.xls,.xlsx,.pdf';
        break;
      case 'excel':
        types = '.xls,.xlsx';
        break;
      // 新增规格说明书
      case 'specification':
        types = '.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx';
        break;
      default:
        types = '.pdf,.doc,.docx,.jpg,.png,.gif,.txt,.xls,.xlsx,.zip';
        break;
    }
    return types;
  });
  const defaultTips = () => {
    // 默认提示语可以根据限制的类型自动显示对应的数值
    const typeStr = accept.value.replace(/\./g, '');
    return `允许类型：${typeStr}   大小上限：${props.size}M    最多上传${props.max}个文件`;
  };
  const isPercentMask = (file: any) => {
    // 根据是否是合法的远程地址来判断是否是上传过程

    if (props.isImport) {
      return true;
    } else {
      const { url, response } = file;
      const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;

      if (Expression.test(url) && url.indexOf('blob:') < 0) {
        return true;
      } else {
        if (response && (response.code === 0 || response.code === 200)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  const onProgress = () => {
    return true;
  };

  const getFilePercent = (file: any) => {
    const res = file.response as UploadResponse;
    let percentage = Number(file.percentage?.toFixed(0));
    const status = file && file.response && res.code === 0;
    if (!status) {
      if (Number(percentage) > 99) {
        percentage = 99;
      }
      return percentage;
    }
  };
  return { accept, defaultTips, isPercentMask, getFilePercent, onProgress };
};
