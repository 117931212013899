
import { useStore } from '@/store';
import { errorMessage, getCutFileName, getSuffix, showFiles } from '@/utils/publicMethods';

import { computed, defineComponent, PropType, ref } from 'vue';
import useFiles from './useFiles';
type FileList =
  | {
      name: string;
      url: string;
    }
  | []
  | null;

export default defineComponent({
  props: {
    /** 上传路由 */
    uploadUrl: {
      type: String,
      default: process.env.VUE_APP_UPLOAD_FILE,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    /** // 最大上传个数 */
    max: {
      type: Number,
      default: 10,
    },
    /** 文件上传类型 */
    fileType: {
      type: [String, Number],
      default: 'default',
    },
    showTips: {
      type: [Boolean],
      default: true,
    },
    tips: {
      type: [String],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      // 单文件最大值
      type: [String, Number],
      default: 30,
    },
    // 1新增 2编辑 3往后查看
    fromType: {
      type: [Number, String],
      default: 1,
    },
    customType: {
      // 自定义展示类型，给证件栏用
      type: String as PropType<'card'>,
      default: '',
    },
    customData: {
      type: Object as PropType<any>,
    },
    customClass: {
      type: [String],
      default: '',
    },
    fileList: {
      type: Array as PropType<any[]>,
    },
    width: {
      type: Number as PropType<number>,
    },
    keyUrl: {
      type: String as PropType<string>,
      default: 'url',
    },
    keyName: {
      type: String as PropType<string>,
      default: 'name',
    },
    isImport: {
      // 是否是导入模式，默认不是
      type: Boolean,
      default: false,
    },
    // 新增请求参数
    uploadParams: {
      type: Object,
    },
    // 新增自定义名称
    name: {
      type: String,
      default: '',
    },
    // 1 标签  2. 规格说明书  3.产品图片
    type: {
      type: Number,
    },
  },
  emits: ['success', 'getFiles'],
  setup(props, ctx) {
    const store = useStore();
    const token = { Authorization: '' };
    // TDDO 通过getters取access_token
    if (store.state.user?.access_token) {
      token.Authorization = 'Bearer ' + store.state.user?.access_token;
    } else if (localStorage.getItem('access_token')) {
      token.Authorization = 'Bearer ' + localStorage.getItem('access_token');
    }

    const { accept, defaultTips, getFilePercent, isPercentMask, onProgress } = useFiles(props);
    const uploadRef = ref();
    const fileCount = ref(0);
    // 赋值回填数据
    const uploadList = computed(() => {
      if (props.fileList && props.fileList.length > 0) {
        const fileList: FileList[] = [];
        (props.fileList as FileList[]).forEach((v) => {
          fileList.push({
            name: v?.[props.keyName],
            url: v?.[props.keyUrl],
          });
        });
        return fileList;
      } else {
        return [];
      }
    });

    const beforeAvatarUpload = (file: File) => {
      let { name, size } = file;
      const suffix = getSuffix(name);
      if (size / 1024 / 1024 <= 0) {
        errorMessage('文件过小');
        return false;
      }
      if (size / 1024 / 1024 > props.size) {
        // 文件超过给定大小
        errorMessage('文件超出限制大小');
        return false;
      }
      if (name.length > 50) {
        // 校验文件名是否大于60个字符，包括文件名后缀
        errorMessage('文件名过长');
        return false;
      }
      if (accept.value.indexOf(suffix) < 0) {
        errorMessage('文件格式不正确');
        return false;
      }
      if (props.customType === 'card') {
        if (props.customData && (props.customData as any).value !== '' && (props.customData as any).value !== null) {
          return true;
        } else {
          errorMessage('请选择证件类型!');
          return false;
        }
      }
      return true;
    };
    interface FileResponse {
      code: number;
      message: string;
      result: {
        name: string;
        url: string;
      };
    }
    /** 返回文件内容用 */
    const getUploadList = (fileList) => {
      const uploadList: any[] = []; // 最后提交出去的数据
      fileList.forEach((item, index) => {
        const suffix = getSuffix(item.name);
        const name = getCutFileName(item.name);
        const itemName = name + '.' + suffix;

        // 自定义文件名 10/25
        if (props.type && props.type > 0) {
          let itemName;
          if (props.type === 1) {
            itemName = props.name + '标签';
          } else if (props.type === 2) {
            itemName = fileList.length > 1 ? props.name + '规格说明书' + (index + 1) : props.name + '规格说明书';
          } else {
            itemName = fileList.length > 1 ? props.name + '产品图片' + (index + 1) : props.name + '产品图片';
          }
          item.name = itemName;
        }

        const data: any = {};
        if (props.customType === 'card') {
          if (!item.label) {
            data.label = JSON.parse(JSON.stringify(props.customData)).label;
            data.customData = JSON.parse(JSON.stringify(props.customData));
            // item.label = JSON.parse(JSON.stringify(props.customData)).label;
            // item.customData = JSON.parse(JSON.stringify(props.customData));
          }
        }
        if (item.response == null) {
          // 不要填写链接进去
          if (item.url && item.url.indexOf('blob') !== 0) {
            data[props.keyName] = itemName;
            data[props.keyUrl] = item.url;
            uploadList.push(data);
          }
        } else {
          // todo miniIo
          data[props.keyName] = itemName;
          data[props.keyUrl] = (item.response as FileResponse).result.url;
          uploadList.push(data);
        }
      });
      if (fileList.length === uploadList.length) {
        // 只有全部上传成功才返回给业务

        ctx.emit('success', uploadList);
      }
    };
    /** 错误失败回调 */
    const checkError = (response) => {
      if (props.isImport) {
        ctx.emit('getFiles', response);
      }
    };
    /** 上传成功回调 */
    const onSuccess = (response, file: any, fileList: any[]) => {
      // todo miniIo
      if (props.isImport) {
        ctx.emit('getFiles', response);
      } else {
        if (response.code !== 200) {
          errorMessage(response.msg);
          fileList.splice(fileList.indexOf(file), 1);
        } else {
          getUploadList(fileList);
        }
      }
    };

    const customRemove = (file) => {
      uploadRef.value.handleRemove(file);
    };

    const handleRemove = (file: any, fileList: any[]) => {
      getUploadList(fileList);
    };

    const isImage = (name: string) => {
      // 显示文件名用
      const arr = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
      const suffix = getSuffix(name);
      return arr.includes(suffix);
    };
    /** 上传数量已达上限 */
    const handelExceedFn = () => {
      errorMessage('上传文件数量已达上限');
    };
    const showFile = (file: any, status: boolean) => {
      if (props.fromType >= 3) {
        showFiles(file.name, file.url as string, status);
      } else {
        return false;
      }
    };
    /** 获取手动修改更改 */
    const handleChange = (file, fileList) => {
      fileCount.value = fileList.length;
    };
    /** 手动上传 */
    const submitUpload = () => {
      uploadRef.value.submit();
    };

    return {
      checkError,
      handleChange,
      fileCount,
      submitUpload,
      token,
      uploadList,
      accept,
      defaultTips,
      beforeAvatarUpload,
      onSuccess,
      handelExceedFn,
      isImage,
      uploadRef,
      customRemove,
      isPercentMask,
      getFilePercent,
      onProgress,
      showFile,
      handleRemove,
    };
  },
});
