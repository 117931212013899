import { AxiosInstance } from 'axios';
import { ElMessage } from 'element-plus';
import { refreshToken } from '.';

let configArrays: any[] = []; // 存储过期后得内容信息
let isRefreshing = false;
const response = (service: AxiosInstance) => {
  service.interceptors.response.use(
    (res) => {
      const contentType = res.headers['content-type'];
      if (contentType && contentType.startsWith('application/json')) {
        if (res.data.type && res.data.type === 'application/json') {
          // blob类型转换为json
          const reader = new FileReader();
          reader.readAsText(res.data);
          reader.onload = () => {
            const content = reader.result; // 内容就在这里
            const jsonObj = JSON.parse(content as string);
            ElMessage({
              message: jsonObj.message,
              type: 'error',
              showClose: true,
            });
            // return JSON.stringify(jsonObj);
          };
        } else {
          return res.data;
        }
      } else {
        return res;
      }
    },
    (error) => {
      if (error && error.response) {
        // 判断是401错误
        const status = error.response.status;
        // eslint-disable-next-line prefer-const
        let config = error.config; // 这里要用let 不然不能修改掉config
        if (status === 401) {
          if (!isRefreshing) {
            isRefreshing = true;
            return refreshToken()
              .then((res) => {
                if (res) {
                  const token = localStorage.getItem('access_token');
                  config.headers.Authorization = token; // 重新更换新token
                  configArrays.forEach((cb) => cb(token)); // 核心代码，这里循环处理后续请求
                  configArrays = [];
                  return service(config);
                }
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            // 核心代码，这里把后续超时的请求放进去
            return new Promise((resolve) => {
              configArrays.push(() => {
                config.baseURL = ''; // 置空baseurl
                config.headers.Authorization = localStorage.getItem('access_token'); // 重新更换新token
                resolve(service(config));
              });
            });
          }
        } else {
          if (error.response.data.type && error.response.data.type === 'application/json') {
            const reader = new FileReader();
            reader.readAsText(error.response.data);
            reader.onload = () => {
              const content = reader.result; // 内容就在这里
              const jsonObj = JSON.parse(content as string);
              ElMessage({
                message: jsonObj.message,
                type: 'error',
                showClose: true,
              });
            };
          } else {
            return error.response.data;
          }
        }
      } else {
        // todo
      }

      Promise.reject(error);
    }
  );
};
export default response;
